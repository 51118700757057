<div class="container">

    <div class="fixed-area">

        <div class="logo">
            <img src="./assets/LOGO@3xDecision 2 1.png">
        </div>
    
        <div id="0" class="main-btns select" (click)="userChangeView(_MainViews.overview)">
            <img [src]="overviewBtn">
            <label>Overview</label>
        </div>
    
        <div id="1" class="main-btns" (click)="userChangeView(_MainViews.auctions)">
            <img [src]="auctionsBtn">
            <label>Auctions</label>
        </div>

        <div class="main-btn-options" *ngIf="showAuctionOptions">
            
            <div id="10" class="option-btn opt-btn-select" (click)="userChangeView(_MainViews.auctions, _SubViews.act_all)">
                All Auctions
            </div>

            <div  id="11" class="option-btn" (click)="userChangeView(_MainViews.auctions, _SubViews.act_add_new)">
                Add New
            </div>

            <div  id="12" class="option-btn" (click)="userChangeView(_MainViews.auctions, _SubViews.act_contracts)">
                Contracts
            </div>

            <div  id="13" class="option-btn" (click)="userChangeView(_MainViews.auctions, _SubViews.act_winners)">
                Winners
            </div>

        </div>
    
        <div  id="2" class="main-btns" (click)="userChangeView(_MainViews.users)">
            <img [src]="usersBtn">
            <label>Users</label>
        </div>

        <div class="main-btn-options" *ngIf="showUsersOptions">
            
            <div id="14" class="option-btn" (click)="userChangeView(_MainViews.users, _SubViews.user_analytics)">
                User Analytics
            </div>

            <div id="15" class="option-btn opt-btn-select" (click)="userChangeView(_MainViews.users, _SubViews.all_users)">
                All Users
            </div>

            <div  id="16" class="option-btn" (click)="userChangeView(_MainViews.users, _SubViews.usr_add_new)">
                Add New
            </div>

            <!-- <div  id="17" class="option-btn" (click)="userChangeView(_MainViews.users, _SubViews.user_profile)">
                Profile
            </div> -->

            <div  id="18" class="option-btn" (click)="userChangeView(_MainViews.users, _SubViews.user_management)">
                User Management
            </div>

        </div>
    
        <!-- <div  id="3" class="main-btns" (click)="userChangeView(_MainViews.message)">
            <img [src]="messageBtn">
            <label>Message</label>
        </div> -->

        <div class="main-btn-options" *ngIf="showMessageOptions">
            
            <div id="19" class="option-btn " (click)="userChangeView(_MainViews.message, _SubViews.msg_mass_msg)">
                Mass Message
            </div>

            <div id="20" class="option-btn opt-btn-select" (click)="userChangeView(_MainViews.message, _SubViews.msg_builder_side)">
                Builder Side
            </div>

            <div  id="21" class="option-btn" (click)="userChangeView(_MainViews.message, _SubViews.msg_client_side)">
                Client Side
            </div>

        </div>
    
        <div id="4" class="main-btns" (click)="userChangeView(_MainViews.ads)">
            <img [src]="adsdBtn">
            <label>Ads</label>
        </div>

        <div class="main-btn-options" *ngIf="showAdsOptions">

            <div id="23" class="option-btn opt-btn-select" (click)="userChangeView(_MainViews.ads, _SubViews.ads_all)">
                All Ads
            </div>

            <div id="22" class="option-btn opt-btn-select" (click)="userChangeView(_MainViews.ads, _SubViews.ads_new)">
                Add New
            </div>

        </div>
    
        <div  id="5" class="main-btns" (click)="userChangeView(_MainViews.archive)">
            <img [src]="archiveBtn">
            <label>Archive</label>
        </div>
    
        <div  id="6" class="main-btns" (click)="userChangeView(_MainViews.settings)">
            <img [src]="settingsBtn">
            <label>Settings</label>
        </div>

        <div class="main-btn-options" *ngIf="showSettingsOptions">
            
            <div id="24" class="option-btn opt-btn-select" (click)="userChangeView(_MainViews.settings, _SubViews.set_general)">
                General
            </div>

            <div id="25" class="option-btn " (click)="userChangeView(_MainViews.settings, _SubViews.set_permalink)">
                Permalink
            </div>

            <div  id="26" class="option-btn" (click)="userChangeView(_MainViews.settings, _SubViews.set_privacy)">
                Privacy
            </div>

        </div>

        <div  id="8" class="main-btns" (click)="userChangeView(_MainViews.contractors)">
            <img [src]="contractorsBtn">
            <label>Contractors</label>
        </div>

        <div class="main-btn-options" *ngIf="showContractorsOptions">
            
            <div id="27" class="option-btn opt-btn-select " (click)="userChangeView(_MainViews.contractors, _SubViews.con_create_scope)">
                Create Scope Of Work
            </div>

            <div id="28" class="option-btn" (click)="userChangeView(_MainViews.contractors, _SubViews.con_save_scope)">
                Saved Scope Of Work
            </div>

            <div id="29" class="option-btn " (click)="userChangeView(_MainViews.contractors, _SubViews.con_project_board)">
                Project Board
            </div>

            <div  id="30" class="option-btn" (click)="userChangeView(_MainViews.contractors, _SubViews.con_bidding_board)">
                Bidding Board
            </div>

        </div>

    </div>


    <div class="grow-area">

        <div id="999" class="main-btns logout-btn" (click)="logout()">
            <img src="./assets/btn-icons/logout.png">
            <label>Logout</label>
        </div>
        
    </div>


</div>
