<div class="ads-all-container">

    <div class="analytics-container">

        <div class="analytics-title-area">

            <div class="analytics-title"> Ads Analytics (Last Month)</div>

            <div class="downloader">

                <img src="./assets/download-icon.png">
    
                <label>Download .xlsx</label>
            </div>
            
        </div>

        <div class="analytics-content">

            <ng-container *ngTemplateOutlet="details2; context: {'detailContentPerformances': detailContentPerformances}"></ng-container>

        </div>

        <div class="dropdownbtn-area">
            <p-dropdown [options]="bulkActions" [(ngModel)]="selectedBulkAction"></p-dropdown>

            <button type="button" (click)="applyBulkAction()" > Apply </button>
        </div>
        

        <div class="table-area">

            <div class="common-ag-grid-styles" style="width: 100%; height: 100%;">
                        
                <ag-grid-angular #agGrid 
                    style="width: 100%; height: 100%; font-size: 12px;" 
                    class="ag-theme-alpine"
                    [columnDefs]="activeColumnDefs"
                    [rowData]="activeRowData"
                    [rowSelection]="rowSelection"
                    [suppressRowClickSelection]="true"
                    
                    
                >
                </ag-grid-angular> 
    
            </div>
        </div>  
      
    </div>

</div>

<ng-template #details2 let-detailContentPerformances = "detailContentPerformances" >

    <div class="details-content" *ngFor="let detailContents of detailContentPerformances">

        <div class="title">{{detailContents.title}}</div>

        <div class="count">{{detailContents.count}}</div>

    </div>

</ng-template>
