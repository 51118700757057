import { Component, OnInit } from '@angular/core';
import { AdminMainViews, AdminSubViews } from '../app.types';
import { Interconnect } from 'ng-interconnect';
// import { ChatService } from '../chat.service';
import { AppService } from '../app.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  currentMainView: number = AdminMainViews.overview;
  _MainViews = AdminMainViews;
  _SubViews = AdminSubViews;
   showContractors :boolean = false;
   broadcaster;
   
  

  constructor(private interconnect: Interconnect, /*private chat: ChatService,*/
    public appService: AppService) { 

      this.broadcaster = this.interconnect.createBroadcaster('home-message');

      interconnect.receiveFrom('contractor-popup', '', (data) => {
        this.showContractors = data;
      });
    }

  ngOnInit(): void {

    //--------------- Home UI Listeners --------------------//
		this.interconnect.createListener('home/changeView', (_connection, command) => {

			this.currentMainView = command.subView || command.mainView;
      
     

      if(this.currentMainView === AdminMainViews.users|| this.currentMainView === AdminSubViews.user_analytics || this.currentMainView === AdminSubViews.user_management || this.currentMainView === AdminSubViews.user_profile || this.currentMainView === AdminSubViews.usr_add_new){

        this.broadcaster.emit(true);

      }
      
      else{
        this.broadcaster.emit(false);

      }

      

      //auction > add new component title changing and data trnsfering
      if(command.userAction){

        this.appService.auctionTitle = "Edit"
        this.appService.autionData = command.data;

      }

      // Edit user(admin) details
      else if(command.user){

        this.appService.profileData = command.data;

      }

      else{

        this.appService.auctionTitle = "Add new";
        this.appService.autionData = '';

      }

		});

    // this.chat.join();
   
  }

}
