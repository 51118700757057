<div class="notify-container" *ngFor="let notificationItem of notifications">

    <div class="notification-image">

        <img [src]="notificationItem.image">

    </div>

    <div class="notification-info">

        <div class="text">

            <div class="name">{{notificationItem.name}}</div> 

            <div class="message">{{notificationItem.message}}</div>

        </div>

        <div>
            <div class="time">{{notificationItem.time}}</div>
            <div *ngIf="notificationItem.count" class="count">{{notificationItem.count}}</div>
        </div>

    </div>
    

</div>
