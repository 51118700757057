import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { IMessageStream, Interconnect } from 'ng-interconnect';
import { AppService } from 'src/app/app.service';
import { AdminMainViews, AdminSubViews, IGridFilterContractors, dropdownItems } from 'src/app/app.types';
import { RegularDropdownComponent } from 'src/app/ds-components/ag-grid/regular-dropdown/regular-dropdown.component';
import { RegularGridPopupComponent } from 'src/app/ds-components/ag-grid/regular-popup/regular-popup.component';
import { ExtapiService } from 'src/app/extapi.service';

@Component({
  selector: 'app-project-board',
  templateUrl: './project-board.component.html',
  styleUrls: ['./project-board.component.scss']
})
export class ProjectBoardComponent implements OnInit {

  private changeView: IMessageStream | Promise<IMessageStream>;
  private changeAuctionView: IMessageStream | Promise<IMessageStream>;
  _MainViews = AdminMainViews;
  _SubViews = AdminSubViews;

  @ViewChild('agGrid', { static: true }) agGrid: AgGridAngular;

  // selectedIndex;
  rowSelection;
  gridFiletr = IGridFilterContractors;
  filteredData;
  filteredDataCount = {
    all: 0,
    active: 0,
    closed: 0,
    trash: 0
  }

  filterTitle = "All";

  activeColumnDefs = [

    {  
      headerName: '',
      field: 'check',
      width: 140,
      resizable: true,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      
    },
    {  
      headerName: 'Contract ID',
      field: 'id',
      width: 245,
      resizable: true,
     
    },
    {  
      headerName: 'Winner’s Address',
      field: 'address',
      width: 500,
      resizable: true,
    },
    {  
      headerName: 'Select SOW',
      field: 'sow',
      width: 300,
      resizable: true,
      cellRendererFramework: RegularDropdownComponent,
      
    },
    {  
      headerName: 'Proceed',
      field: 'proceed',
      width: 300,
      resizable: true,
      cellRenderer: this.startEstimationsRenderer
      
    },
    
    {  
      headerName: '',
      field: 'action',
      width: 50,
      resizable: true,
      cellRendererFramework: RegularGridPopupComponent,
      cellRendererParams: {
        items:[
          {
            label: 'Saved Scope Of Work',
            id: 6,
            color:"rgba(74, 137, 152, 1)"
          },
          {
            label: 'Edit',
            id: 7
          },
          {
            label: 'Trash',
            id: 8
          }
        ],
        clickHandler: this.dropdownClickHander.bind(this)
          
      }
      
    } 
  
  ];
  
  activeRowData = [
    
    {"id": "A0B1C091",
      "status":"Saved",
      "address":"2881 Summer Lane Portland, OR 98177",
      "submissions":"19"
    },

    {"id": "A0B1C091",
    "status":"Draft",
      "address":"2881 Summer Lane Portland, OR 98177",
      "submissions":"60"
    },

    {"id": "A0B1C091",
    "status":"Trash",
    "address":"2881 Summer Lane Portland, OR 98177",
    "submissions":"72"
    }

  ];

  bulkActions = [
    {
      label: 'Bulk Actions'
    },
    {
      label: 'Trash',
      value: {
        id: 1,
        name: 'Trash'
      }
    },
    {
      label: 'Delete',
      value: {
        id: 2,
        name: 'Delete'
      }
    }
  ]

  selectedRowData;
  option = dropdownItems;
  selectedBulkAction;

  constructor(
    private elem: ElementRef,
    public  extApi:  ExtapiService,
    public appService: AppService,
    private interconnect: Interconnect,
    
    ) { 

    this.rowSelection = 'multiple';

    this.changeView = interconnect.connectToListener('home/changeView', 'leftbar');
    if (this.changeView['then']) {
      this.changeView['then']((notifier) => this.changeView = notifier);
    }

  }

  async ngOnInit() {

    

    // try {

    //   this.activeRowData = await this.extApi.viewAllAuctions({});

    //   this.activeRowData.forEach(aRow => {
    //     aRow.date = moment(aRow.date).format("MM-DD-YYYY HH:mm:ss")
    //   })

       this.refresh();
      
    // } 
    // catch (error) {

    //   console.log(error);
    //   alert(error.statusText);
      
    // }
    console.log(this.activeRowData)
  }


  gridFilter(filetr: IGridFilterContractors){

    let tabButtons = Array.from(this.elem.nativeElement.querySelectorAll('.filters'));

    tabButtons.forEach((tab: any) => {

      let btnId = tab.id;

      if(btnId === filetr)
        tab.classList.add('select');
      else
        tab.classList.remove('select');

    });

    switch(filetr){
      case this.gridFiletr.all: 
        this.filteredData = this.activeRowData; 
        this.filterTitle = "All"; 
      break;

      
      case this.gridFiletr.active: 
        this.filteredData = this.activeRowData.filter(aRow => aRow.status === 'Active');
        this.filterTitle = "Active";
        break;

      case this.gridFiletr.closed: 
        this.filteredData = this.activeRowData.filter(aRow => aRow.status === 'Closed');
        this.filterTitle = "Closed";
        break;

      case this.gridFiletr.trash: 
        this.filteredData = this.activeRowData.filter(aRow => aRow.status === 'Trash'); 
        this.filterTitle = "Trash";
        break;
    }

  }

  onCellClicked(params) {

    if (params.event.target.dataset.action == 'toggle' && params.column.getColId() == 'action') {

      const cellRendererInstances = params.api.getCellRendererInstances({
        rowNodes: [params.node],
        columns: [params.column],
      });

      if (cellRendererInstances.length > 0) {
        const instance = cellRendererInstances[0].getFrameworkComponentInstance();
        instance.togglePopup();
      }

    }

    this.selectedRowData = params.data;
    

  }

  async dropdownClickHander(params:dropdownItems) {

    switch (params) {

      case this.option.Edit:
        // this.userChangeView(this._MainViews.auctions, this._SubViews.act_add_new);
        break;

      case this.option.Trash:
        var data = this.selectedRowData;
        // data.status = AUCStatus.Trash;
        // try {

        //   await this.extApi.updateAuctionStatus({data});
          
        // } 
        // catch (error) {

        //   console.log(error);
        //   alert(error.statusText); 
        // }
        break;

      case this.option.SavedScopeOfWork:
        break;
    

      default:
        break;

    }

    this.refresh();
    this.agGrid.api.redrawRows();

    
  }

  async refresh(){

    // this.activeRowData.forEach(aRow => {

    //   switch(aRow.status){
    //     case AUCStatus.Active: aRow.status = "Active"; break;
    //     case AUCStatus.Draft: aRow.status = "Draft"; break;
    //     case AUCStatus.Trash: aRow.status = "Trash"; break;
    //     case AUCStatus.Unpublished: aRow.status = "Unpublish"; break;
    //   }

    // })

    this.filteredData = this.activeRowData;

    this.filteredDataCount = {
      all: this.activeRowData.length,
      active: this.activeRowData.filter(aRow => aRow.status === 'Active').length,
      closed: this.activeRowData.filter(aRow => aRow.status === 'Closed').length,
      trash: this.activeRowData.filter(aRow => aRow.status === 'Trash').length
    }

  }

  async applyBulkAction(){

    // var selectedRows = this.agGrid.api.getSelectedRows();

    
    // if(selectedRows.length){

    //   if(this.selectedBulkAction){

    //     var action = this.selectedBulkAction.id;

    //     var ids = [];
  
    //     switch (action) {
  
    //       case auctionBulkActions.Delete:
  
    //         selectedRows.forEach(aRow => {
  
    //           ids.push(aRow.auction_id)
              
   
    //         });
  
    //         try {
  
    //           await this.extApi.deleteAuctions(ids);
    //           this.agGrid.api.deselectAll();
    //           this.filteredData = this.filteredData.filter((x) =>{
    //           return selectedRows.indexOf(x) < 0;
    //           });
    //           this.activeRowData = await this.extApi.viewAllAuctions({});
    //           this.refresh();
    //           this.agGrid.api.redrawRows();
    //           this.selectedBulkAction = this.bulkActions[0];
              
    //         } 
  
    //         catch (error) {
  
    //           console.log(error);
    //           alert(error.statusText);
              
    //         }
    //         break;
    
    //     }

    //     ids = [];

    //   }

    //   else{

    //     alert("please select a action")

    //   } 
     
    // }
    
    // else{

    //   alert("please select the rows")
    // }

  }


  startEstimationsRenderer(params:any){
 
    var ui = document.createElement('div');

    ui.innerHTML =
      `
        <div style=" text-align: center; color:#F9A369"><u>Start Estimations</u></div>
        
      `;

    return ui;

  }

}

