import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IMessageStream, Interconnect } from 'ng-interconnect';
import { AdminMainViews, AdminSubViews, userStatus } from 'src/app/app.types';
import { ExtapiService } from 'src/app/extapi.service';

@Component({
  selector: 'app-add-new-users',
  templateUrl: './add-new-users.component.html',
  styleUrls: ['./add-new-users.component.scss']
})
export class AddNewUsersComponent implements OnInit {

  @ViewChild('pwd') pwd: ElementRef;
  @ViewChild('icon') icon: ElementRef;

  private changeView: IMessageStream | Promise<IMessageStream>;
  _MainViews = AdminMainViews;
  _SubViews = AdminSubViews;

  username;
  firstName;
  lastName;
  email;
  password;
  role;
  status;

  roles = [
    {
      label: 'Administrator',
      value: {
        id: 1,
        name: 'Administrator'
      }
    },
    {
      label: 'Subscriber',
      value: {
        id: 2,
        name: 'Subscriber'
      }
    },
    {
      label: 'Premium',
      value: {
        id: 3,
        name: 'Premium'
      }
    },
    {
      label: 'Builder',
      value: {
        id: 4,
        name: 'Builder'
      }
    }
  ]

  constructor(
    public extApi:  ExtapiService,
    private interconnect: Interconnect

  ) { 

    this.changeView = interconnect.connectToListener('home/changeView', 'leftbar');
    if (this.changeView['then']) {
      this.changeView['then']((notifier) => this.changeView = notifier);
    }
  }

  ngOnInit(): void {

    this.role = this.roles[0].value;

  }

  showPassword(){

    const password = this.pwd.nativeElement;
    const eyeIcon = this.icon.nativeElement;

    const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
    password.setAttribute('type', type);

    eyeIcon.classList.toggle("pi-eye");
        
  }

  async addUser(){

    try {

      if(this.username && this.email){

        var date =new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000).toJSON().slice(0, 19).replace('T', ' ');

        let user = {

          username:this.username,
          firstName:this.firstName,
          lastName:this.lastName,
          fullName:this.firstName +" "+this.lastName,
          email:this.email,
          password:this.password,
          role:this.role.id,
          status:userStatus.Active,
          date: date

        }

        let newUser = await this.extApi.addNewUser({user});

        this.username = '';
        this.firstName = '';
        this.lastName = '';
        this.email = '';
        this.password = '';
        this.role = this.roles[0].value;
        this.userChangeView();

      }

      else {

        alert("Fill all fields")
        
      }
      
    } 
    
    catch (error) {

      console.log(error);
      alert(error.statusText);
      
    }   

  }

  userChangeView(){

    (this.changeView as IMessageStream).emit({mainView: AdminMainViews.users, subView: AdminSubViews.all_users});

  }

  generatePassword(){

    var chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var passwordLength = 20;
    var password = "";

    for (var i = 0; i <= passwordLength; i++) {
      var randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber +1);
    }

    this.password = password;

  }

}
