import { Component, OnInit } from '@angular/core';
import { Interconnect, IMessageStream } from 'ng-interconnect';
import { AdminMainViews, AdminSubViews } from 'src/app/app.types';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {

  private changeView: IMessageStream | Promise<IMessageStream>;
  _MainViews = AdminMainViews;
  _SubViews = AdminSubViews;
  currentMainView;
  showContractorsButton: boolean = false;
  showContractorsBtn;
  broadcaster;

  constructor(
    private interconnect: Interconnect
  ) {

    this.changeView = interconnect.connectToListener('home/changeView', 'leftbar');
    if (this.changeView['then']) {
      this.changeView['then']((notifier) => this.changeView = notifier);
    }


    // enable Contractors Button
    interconnect.receiveFrom('home-message', '', (data) => {
      this.showContractorsButton = data;
    });

    this.broadcaster = this.interconnect.createBroadcaster('contractor-popup');

   }

  ngOnInit(): void {}

  userChangeView(){

    (this.changeView as IMessageStream).emit({mainView: this._MainViews.profile});

    // this.buttonsIconChange(viewId, subViewId);


  }

  showContractorsPopup(){

   
    this.broadcaster.emit(true)


  }

  enableContractorsBtn(){

    //contractors button show only users component

  //   this.interconnect.receiveFrom('topbar-message', '', (data) => {
  //     console.log(data);
  //   }
  //  );


  }


}
