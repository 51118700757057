import { Component, ElementRef, Injectable, OnInit, ViewChild } from '@angular/core';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { ExtapiService } from 'src/app/extapi.service';
import { Interconnect, IMessageStream } from 'ng-interconnect';
import { AUCCategories, AUCStatus, AdminMainViews, AdminSubViews } from '../../app.types';

@Component({
  selector: 'app-auction-add-new',
  templateUrl: './auction-add-new.component.html',
  styleUrls: ['./auction-add-new.component.scss']
})
export class AuctionAddNewComponent implements OnInit {

  private changeView: IMessageStream | Promise<IMessageStream>;
  _MainViews = AdminMainViews;
  _SubViews = AdminSubViews;

  auctions: any;
  entrants:any;
  text: string;
  text2: string;
  uploadedFiles: any[] = [];

  title;
  description;
  contract;
  category;
  biddingRooms;
  startingBid;
  entrantsAmount;
  tokenAllowance;
  coverimage; 
  enterFee = 50;

  image: string | SafeUrl = './assets/img-icon.png';

  mainTitle;
  data =[];
  id;

  fileContent: any = '';
  clickChooseContract: boolean = false;
  choosenContractDetails;

  bidIntervalOptions = [
    {
      label: '8 hours',
      value: {
        id: 1,
        name: '8',
        amount:8
      }
    },
    // {
    //   label: '6 Hours',
    //   value: {
    //     id: 2,
    //     name: '6',
    //     amount:6
    //   }
    // }
  ];

  bidInterval;
  showBidInterval: boolean = false;
  contractFileName = "";

  genaratedAUCID = new Date().getUTCMilliseconds();
  contractName = '';

  selectedTemplate = 'template1';
  
  constructor(
    private sanitizer: DomSanitizer,
    public appService: AppService,
    public  extApi:  ExtapiService,
    private interconnect: Interconnect,
    
  ) { 

    this.auctions = [
      {
        label: 'Single Auction',
        value: {
          id: 1,
          name: 'Single'
        }
      },
      {
        label: 'Duplex Auction',
        value: {
          id: 2,
          name: 'Duplex'
        }
      },
      {
        label: 'Commercial Auction',
        value: {
          id: 3,
          name: 'Commercial'
        }
      }
    ]

    this.entrants = [
      {
        label: '5000',
        value: {
          id: 1,
          name: '5000',
          amount:5000
        }
      },
      {
        label: '10,000',
        value: {
          id: 2,
          name: '10,000',
          amount:10000
        }
      },
      {
        label: '15,000',
        value: {
          id: 3,
          name: '15,000',
          amount:15000
        }
      },
      {
        label: '20,000',
        value: {
          id: 4,
          name: '20,000',
          amount:20000
        }
      },
      {
        label: '25,000',
        value: {
          id: 5,
          name: '25,000',
          amount:25000
        }
      },
      {
        label: '50,000',
        value: {
          id: 6,
          name: '50,000',
          amount:50000
        }
      }
    ]

    this.changeView = interconnect.connectToListener('home/changeView', 'leftbar');
    if (this.changeView['then']) {
      this.changeView['then']((notifier) => this.changeView = notifier);
    }

    interconnect.receiveFrom('choose-contract-popup', '', (data) => {
      this.choosenContractDetails = data;
      this.clickChooseContract = data.display;
      this.contractFileName = data.name;

      if(data.description){
        this.contract = data.description;

      }
    });

 
  }

  ngOnInit(){


    this.mainTitle = this.appService.auctionTitle;

    this.genaratedAUCID = this.appService.autionData.auction_id || this.genaratedAUCID;
    this.title = this.appService.autionData.title || "";
    this.description = this.appService.autionData.description ? decodeURIComponent(escape(window.atob(this.appService.autionData.description))) : "";
    this.category = this.appService.autionData.category ? this.auctions.find(auc => auc.value.name === this.appService.autionData.category).value : this.auctions[0].value;
    this.biddingRooms = this.appService.autionData.bidding_rooms || 1;
    this.contract = this.appService.autionData.contract || "";
    this.image = this.appService.autionData.coverimage || './assets/img-icon.png';
    this.coverimage = this.appService.autionData.coverimage || "";
    this.entrantsAmount = this.appService.autionData?.entrants?.amount || this.entrants[0].value;
    this.startingBid = this.appService.autionData.starting_bid || "";
    this.tokenAllowance = this.appService.autionData.token_allowance || "";
    this.enterFee = this.appService.autionData.enter_fee || 50;
    this.contractName = this.appService.autionData.contract_name || "";
    this.contractFileName = this.appService.autionData.contract_file_name || "";
    this.bidInterval = this.appService.autionData.bid_interval || this.bidIntervalOptions[0].value;
    this.selectedTemplate = this.appService.autionData.template || 'template1';

    if(this.appService.autionData.category === AUCCategories.Commercial)
      this.showBidInterval = true;
    else
      this.showBidInterval = false;
    

    
  }

  async updateImage(ev) {

    this.image = this.sanitizer.bypassSecurityTrustUrl(
      window.URL.createObjectURL(ev.target.files[0])
    );

    this.coverimage = await this.convertBase64(ev.target.files[0]);
    
  }

  convertBase64 = (file) => {

    return new Promise((resolve, reject) => {

        const fileReader = new FileReader();

        fileReader.readAsDataURL(file);

        fileReader.onload = () => {

            resolve(fileReader.result.toString());
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    });
  };

  async publish(){
    
    try {
      
     
      if (this.title && this.contract && this.biddingRooms && this.startingBid && this.category) {

        var date = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000).toJSON().slice(0, 19).replace('T', ' ');
      
        var newAuction = {
          auctionId: this.genaratedAUCID,
          title:this.title,
          contractName: this.contractName,
          contractFileName: this.contractFileName,
          description: window.btoa(unescape(encodeURIComponent(this.description))),
          contract: this.contract,
          category:this.category.id,
          biddingRooms:this.biddingRooms,
          bidInterval: this.bidInterval.amount,
          startingBid:this.startingBid,
          entrants:this.entrantsAmount?.amount || 0,
          tokenAllowance:this.tokenAllowance || 0,
          coverimage:this.coverimage,
          status:AUCStatus.Active,
          date: date,
          enterFee: this.enterFee,
          template: this.selectedTemplate
        }

        //check edit or new one
        // if(!this.id){

        //   let addAuction = await this.extApi.addNewAuction(newAuction);
         
        // }

        // else{

        //   var editAuction = Object.assign({},newAuction,{id:this.id});
    
        //   let addAuction = await this.extApi.updateAuction(editAuction);

        // }

        await this.extApi.addNewAuction(newAuction);
        
        this.title = '';
        this.description = '';
        this.contract = '';
        this.biddingRooms = 1;
        this.startingBid = '';
        this.tokenAllowance = '';
        this.image = './assets/img-icon.png';
        this.coverimage = '';
        this.category = this.auctions[0].value;
        this.entrantsAmount = this.entrants[0].value;
        this.enterFee = 50;
        this.bidInterval = this.bidIntervalOptions[0].value;

        this.userChangeView(this._MainViews.auctions, this._SubViews.act_all);
  
        
      } 
      
      else {

        alert("Fill in all fields")
        
      }
      
    } 

    catch (error) {

      console.log(error);
      alert(error.statusText);
      
    }
    
  }

  async draft(){

    try {

      // this.contract = this.fileContent;

      var date =new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000).toJSON().slice(0, 19).replace('T', ' ');

      var newAuction = {
        auctionId: this.genaratedAUCID,
        title:this.title,
        contractName: this.contractName,
        contractFileName: this.contractFileName,
        description: window.btoa(unescape(encodeURIComponent(this.description))),
        contract: this.contract,
        category:this.category.id,
        biddingRooms:this.biddingRooms,
        bidInterval: this.bidInterval.amount,
        startingBid:this.startingBid,
        entrants:this.entrantsAmount?.amount || 0,
        tokenAllowance:this.tokenAllowance || 0,
        coverimage:this.coverimage,
        status:AUCStatus.Draft,
        date: date,
        enterFee: this.enterFee,
        template: this.selectedTemplate
      }

      //  //check edit or new one
      // if(!this.id){

      //   let addAuction = await this.extApi.addNewAuction(newAuction);

      // }

      // else{

      //   var editAuction = Object.assign({},newAuction,{id:this.id});
  
      //   let addAuction = await this.extApi.updateAuction(editAuction);

      // }

      await this.extApi.addNewAuction(newAuction);
      
      this.title = '';
      this.description = '';
      this.contract = '';
      this.biddingRooms = 1;
      this.startingBid = '';
      this.tokenAllowance = '';
      this.image = './assets/img-icon.png';
      this.coverimage = '';
      this.category = this.auctions[0].value;
      this.entrantsAmount = this.entrants[0].value;
      this.enterFee = 50;
      this.bidInterval = this.bidIntervalOptions[0].value;


      
      this.userChangeView(this._MainViews.auctions, this._SubViews.act_all);

    }

      
    catch (error) {

      console.log(error);
      alert(error.statusText);
      
    }
    
  }

  selectTemplate(template){
    this.selectedTemplate = template;
  }

  userChangeView(viewId, subViewId = undefined){

    (this.changeView as IMessageStream).emit({mainView: viewId, subView: subViewId});

  }

  showEnterFeeAndNumberOfBiddingRooms(){

    switch (this.category.id) {

      case AUCCategories.Single:

        this.enterFee = 50;
        this.biddingRooms = 1;
        this.showBidInterval = false;
        
        break;
    
      case AUCCategories.Duplex:

        this.enterFee = 100;
        this.biddingRooms = 2;
        this.showBidInterval = false;

        
        break;

      case AUCCategories.Commercial:

        this.enterFee = 125;
        this.biddingRooms = 4;
        this.showBidInterval = true;
        
        break;

    }

  }

  onChange(fileList: any): void {

    let file = fileList.target.files[0];
    let fileReader: FileReader = new FileReader();
    let self = this;
    this.contractFileName = file.name;

    fileReader.onloadend = function (e) {

      self.contract = fileReader.result.toString();

    };

    fileReader.readAsDataURL(file);


  }

  chooseContract(){

    this.clickChooseContract = true;
    
  }

  async uploadFile(e, filename = ""){

    this.contract= await this.convertBase64(e);
    this.contractFileName = e.name || filename;

  }


  allowDrop(ev) {
    ev.preventDefault();
  }

  dropContract(ev) {
    
    ev.preventDefault();
    
    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (const item of ev.dataTransfer.items) {
        if (item.kind !== 'file') {
          return;
        }
        this.uploadFile(item.getAsFile());
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (const file of ev.dataTransfer.files) {
        this.uploadFile(file);
      }
    }

  }

  

}
