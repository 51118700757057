<div class="contractors-container" [style.display]="default ? 'flex' : 'none'" >

    <div class="title-area">

        <div class="title">Auctions > Contracts</div>

    </div>

    <div class="new-contract">
        <button type="button" (click)="openNew()">+ New Contract</button>
    </div>

    <div class="contractors-content">

        <div class="tab-area">

            <div [id]="gridFiletr.all" class="filters select" style="width: 135px;" (click)="gridFilter(gridFiletr.all)">All ({{filteredDataCount.all}})</div>
            <div [id]="gridFiletr.active" class="filters" style="width: 150px;" (click)="gridFilter(gridFiletr.active)">Active ({{filteredDataCount.active}})</div>
            <div [id]="gridFiletr.unpublished" class="filters" (click)="gridFilter(gridFiletr.unpublished)">Unpublished ({{filteredDataCount.unpublished}})</div>
            <div [id]="gridFiletr.draft" class="filters" style="width: 150px;" (click)="gridFilter(gridFiletr.draft)">Draft ({{filteredDataCount.draft}})</div>
            <div [id]="gridFiletr.trash" class="filters" style="width: 150px; border-right: unset;" (click)="gridFilter(gridFiletr.trash)">Trash ({{filteredDataCount.trash}})</div>

        </div>

        <div class="dropdownbtn-area">
            <p-dropdown [options]="bulkActions" [(ngModel)]="selectedBulkAction"></p-dropdown>

            <button type="button" (click)="applyBulkAction()">Apply</button>
        </div>
        
    </div>

    <div class="table-area">

        <div class="common-ag-grid-styles" style="width: 100%; height: 100%;">
                    
            <ag-grid-angular #agGrid 
                style="width: 100%; height: 100%; font-size: 12px;" 
                class="ag-theme-alpine"
                [rowData]="filteredData"
                [columnDefs]="activeColumnDefs"
                [suppressRowClickSelection]="true"
                [rowSelection]="rowSelection"
                (rowSelected)="onRowSelected($event)"
                (cellClicked)="onCellClicked($event)"
            >
            </ag-grid-angular>

        </div>

    </div>

</div>



<div class="contractors-container2" [style.display]="addNew ? 'flex' : 'none'">

   
    <div class="title">Auctions > Contract > Add New </div>
     
  
    <div class="name-area">

        <div class="sub-title">Contract Name</div>

        <input type="text" [(ngModel)]="contractName" pInputText placeholder="Type Here">  
        
    </div>

    <div class="contract-area">
        <label for="con" class="choose">Choose Contract</label>
        <input (change)="uploadFile($event)" style="height: 0px;" type="file" id="con" name="con" accept="application/pdf">

        <div>{{contractFileName}}</div>
    </div>

    <div class="description-area">

        <div class="sub-title">Add Description</div>

        <p-editor [(ngModel)]="description" [style]="{'height':'298px'}"></p-editor>

    </div>

    <div class="btn-area">

        <button type="button" style="background-color: #4A8998;"  (click)="publishContract()" >Publish</button>

        <button type="button" style="color: #4A8998;" (click)="saveDraftContract()">Save Draft</button>

    </div>

</div>

<div class="contractors-container2"  [style.display]="openEdit ? 'flex' : 'none'">

   
    <div class="title">Auctions >Edit Contract </div>
     
  
    <div class="name-area">

        <div class="sub-title">Contract Name</div>

        <input type="text" pInputText placeholder="Type Here" [(ngModel)]="contractName">  
        
    </div>

    <div class="description-area">

        <div class="sub-title">Add Description</div>

        <p-editor [(ngModel)]="contractDescription" [style]="{'height':'298px'}"></p-editor>

    </div>

    <div class="btn-area">

        <button type="button" style="background-color: #4A8998;" (click)="publishContract()">Publish</button>

        <button type="button" style="color: #4A8998;" (click)="saveDraftContract()">Save Draft</button>

    </div>

</div>