import { Component} from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'grid-regular-button',
  template: `<div (click)="clickHandler($event)" style="color: #F9A369; text-align: center;"><u>View</u></div>`
})
export class OpenPortalComponent implements ICellRendererAngularComp {

  params: any;

  constructor(
  ) {}

  agInit(params: any): void {
    this.params = params;
  }

  clickHandler(event) {
  
    if(this.params.clickHandler)
      this.params.clickHandler(this.params)

  }



  refresh(params?: any): boolean {

    this.params = params;

    return true;
  }

}