<div class="container popup-cotroller">

    <p-dialog [(visible)]="display" position="top" [draggable]="false" (onHide)="close()" [transitionOptions]="'200ms'">

        <p-header>
            {{popupData.username}}'s Portal View
        </p-header>

        <div class="top-bar">

            <div class="profile-img-wrapper">
                <img class="prop-image" [src]="popupData.profile_image">
                <label>{{popupData.username}}</label>
            </div>

            <div class="buttons-area">

                <div class="link-button">
                    <u>Update</u>
                </div>

                <img src="./assets/winners/approve.png">

                <img src="./assets/winners/decline.png">

                <div class="link-button">
                    <u>
                        Schedule a Meeting
                    </u>
                </div>

            </div>

        </div>


        <div class="title">
            {{popupData.title}}
        </div>

        <div class="details">

            <div class="header">
                Documents
            </div>

            <table>

                <tr>
                    <th>Requirements</th>
                    <th>Document</th>
                    <th>Date Requested</th>
                    <th>Date Submitted</th>
                    <th>Status</th>
                </tr>
                <tr *ngFor="let field of tableFields">
                    <td>
                        {{field.Requirements.value}}
                    </td>
                    <td>
                        {{field.Document.value}}
                    </td>
                    <td>
                        {{field.DateRequested.value}}
                    </td>
                    <td>
                        {{field.DateSubmitted.value}}
                    </td>
                    <td>
                        <select>
                            <option *ngFor="let action of acttions" [value]="action">{{action}}</option>
                        </select>
                    </td>
                </tr>
            </table>

            <div class="header" style="margin-top: 100px;">
                Eligibility Questions
            </div>

            <div class="questions">

                <div class="question">
                    1. Are you at least 18 years of age or older?
                </div>

                <div class="answer">

                    <input type="radio" id="age-yes" name="age" [checked]="true" [disabled]="true">
                    <label for="age-yes">YES</label><br>

                    <input type="radio" id="age-no" name="age" [disabled]="true">
                    <label for="age-no">NO</label><br>

                </div>

                <div class="question">
                    2. Is your home located within the 48 lower states of america?
                </div>

                <div class="answer">

                    <input type="radio" id="loc-yes" name="loc" [checked]="true" [disabled]="true">
                    <label for="loc-yes">YES</label><br>

                    <input type="radio" id="loc-no" name="loc" [disabled]="true">
                    <label for="loc-no">NO</label><br>

                </div>

                <div class="question">
                    3. Is your home located or declared Historical?
                </div>

                <div class="answer">

                    <input type="radio" id="declared-yes" name="declared" [checked]="true" [disabled]="true">
                    <label for="declared-yes">YES</label><br>

                    <input type="radio" id="declared-no" name="declared" [disabled]="true">
                    <label for="declared-no">NO</label><br>

                </div>

                <div class="question">
                    4. What was your median household income last year?
                </div>

                <div class="answer">

                    <label>$</label>
                    <input type="number" [disabled]="true">

                </div>

                <div class="question">
                    5. What was your net total income last year?
                </div>

                <div class="answer">

                    <label>$</label>
                    <input type="number" [disabled]="true">

                </div>

                <div class="question">
                    6. What is your address?
                </div>

                <div class="answer">

                    <input type="text" style="width: 500px; margin-left: 6px;" [disabled]="true">

                </div>

                <div class="question">
                    7. Are you disabled and need assistance in home or online completing this application?
                </div>

                <div class="answer">

                    <input type="radio" id="disablied-yes" name="disablied" [checked]="true" [disabled]="true">
                    <label for="disablied-yes">Yes, I am Disablied in Home</label><br>

                    <input type="radio" id="disablied-yes-ass" name="disablied" [disabled]="true">
                    <label for="disablied-yes-ass">Yes, I need assistance completing this application.</label><br>

                    <input type="radio" id="disablied-no" name="disablied" [disabled]="true">
                    <label for="disablied-no">No, I am abled bodied</label><br>

                </div>

                <div class="question">
                    8. Have you lived in and occupied the home at least 5 years prior to winning the DreamStakes Contract?
                </div>

                <div class="answer">

                    <input type="radio" id="con-yes" name="con" [checked]="true" [disabled]="true">
                    <label for="con-yes">YES</label><br>

                    <input type="radio" id="con-no" name="con" [disabled]="true">
                    <label for="con-no">NO</label><br>

                </div>

                <div class="question">
                    9. Is the account holder also the homeowner of your address?
                </div>

                <div class="answer">

                    <input type="radio" id="account-yes" name="account" [checked]="true" [disabled]="true">
                    <label for="account-yes">YES</label><br>

                    <input type="radio" id="account-no" name="account" [disabled]="true">
                    <label for="account-no">NO</label><br>

                </div>

                <div class="question">
                    10. Are there any liens against the deed of your home?
                </div>

                <div class="answer">

                    <input type="radio" id="deed-yes" name="deed" [checked]="true" [disabled]="true">
                    <label for="deed-yes">YES</label><br>

                    <input type="radio" id="deed-no" name="deed" [disabled]="true">
                    <label for="deed-no">NO</label><br>

                </div>

                <div class="question">
                    11. Are there any past due utility bills?
                </div>

                <div class="answer">

                    <input type="radio" id="utility-yes" name="utility" [checked]="true" [disabled]="true">
                    <label for="utility-yes">YES</label><br>

                    <input type="radio" id="utility-no" name="utility" [disabled]="true">
                    <label for="utility-no">NO</label><br>

                </div>

                <div class="question">
                    12. Are your current on all property taxes?
                </div>

                <div class="answer">

                    <input type="radio" id="taxes-yes" name="taxes" [checked]="true" [disabled]="true">
                    <label for="age-yes">YES</label><br>

                    <input type="radio" id="taxes-no" name="taxes" [disabled]="true">
                    <label for="taxes-no">NO</label><br>

                </div>

                <div class="question">
                    13. Are you current on your home property taxes?
                </div>

                <div class="answer">

                    <input type="radio" id="property-yes" name="property" [checked]="true" [disabled]="true">
                    <label for="property-yes">YES</label><br>

                    <input type="radio" id="property-no" name="property" [disabled]="true">
                    <label for="property-no">NO</label><br>

                </div>

                
                <div class="question">
                    14. Do you have homeowners insurance?
                </div>

                <div class="answer">

                    <input type="radio" id="homeowners-yes" name="homeowners" [checked]="true" [disabled]="true">
                    <label for="homeowners-yes">YES</label><br>

                    <input type="radio" id="homeowners-no" name="homeowners" [disabled]="true">
                    <label for="homeowners-no">NO</label><br>

                </div>

            </div>



        </div>

        

    </p-dialog>

</div>