import { Injectable, Injector } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ExtapiService } from '../extapi.service';
import {HttpBackend, HttpClient} from '@angular/common/http';
import { AppConfigService } from '../app-config.service';

let InjectorInstance: Injector;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  httpClient: HttpClient;

  constructor(
    private extapi: ExtapiService,
    public jwtHelper: JwtHelperService,
    private handler: HttpBackend,
    public appConfig: AppConfigService,
    private injector: Injector
  ) {

    this.httpClient = new HttpClient(handler);
    InjectorInstance = this.injector;

  }

  login(email, password) {

    return new Promise(async (resolve, reject) => {

      try{

        let data = await this.extapi.createToken({email, password});

        if(data.success){
          localStorage.setItem("ds-admin-token", data.token);
          localStorage.setItem("admin_user_credentials", JSON.stringify({email, password}));
          resolve(true);
        }
        else{
          alert("Login failed");
          reject(false);
        }


      }
      catch(err){
        alert("Login failed");
        reject(false);
      }


      

    })

  } 

  logout() {
    localStorage.removeItem('ds-admin-token');
    localStorage.removeItem('admin_user_credentials');
  }

  public isAuthenticated(): boolean {
    
    let token = localStorage.getItem('ds-admin-token');
    
    // Check whether the token is expired and return
    // true or false
    if (!this.jwtHelper.isTokenExpired(token)) {
      return true;
    }

    return false;
  }

}


export async function tokenGetter(req) {

  let authService = InjectorInstance.get(AuthService);

  let userCredentials = JSON.parse(localStorage.getItem("admin_user_credentials"));
  

    let token = localStorage.getItem("ds-admin-token");

    if(authService.jwtHelper.isTokenExpired(token)){

      if(userCredentials){

        let token = await this.extapi.createToken({email: userCredentials.email, password: userCredentials.password});

        localStorage.setItem("ds-admin-token", token);
        localStorage.setItem("admin_user_credentials", JSON.stringify({email: userCredentials.email, password: userCredentials.password}));

      }
      else{
        console.error("User credentials not found.")
        return localStorage.getItem("ds-admin-token");
      }
        

    }
    else
      return localStorage.getItem("ds-admin-token");


}


export function authScheme(req): string{

  return "Bearer ";

}