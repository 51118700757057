import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IGridFilter2, userBulkActions, userRole, userStatus } from '../app.types';
import { AgGridAngular } from 'ag-grid-angular';
import { ExtapiService } from '../extapi.service';
import { RecoverBtnComponent } from '../ds-components/recover-btn/recover-btn.component';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  
  @ViewChild('deactiveUsers', { static: true }) deactiveUsers: AgGridAngular;

  // selectedIndex;
  rowSelection;
  gridFiletr = IGridFilter2;
  filteredData;
  filteredDataCount = {
    Deleted: 0,
    Banned: 0,
  }

  selectedBulkAction;

  filterTitle = "Deleted";

  activeColumnDefs = [

    {  
      headerName: '',
      field: 'check',
      width: 200,
      resizable: true,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      
    },
    {  
      headerName: 'User Name',
      field: 'username',
      width: 350,
      resizable: true,
      cellRenderer: this.userRenderer
      
    },
    {  
      headerName: 'Name',
      field: 'full_name',
      width: 300,
      resizable: true,
     
    },
    {  
      headerName: 'Email',
      field: 'email',
      width: 370,
      resizable: true,
      
    },
    {  
      headerName: 'Role',
      field: 'role',
      width: 220,
      resizable: true,
      
    },
    {  
      headerName: 'Recover',
      field: 'recover',
      width: 150,
      resizable: true,
      cellRendererFramework: RecoverBtnComponent,
      cellRendererParams: { clickHandler: (params) => this.recoverUser(params)}
      
    }
  
  ];
  

  activeRowData = [];

  bulkActions = [
    {
      label: 'Bulk Actions'
    },
    {
      label: 'Recover',
      value: {
        id: 3,
        name: 'Recover'
      }
    },
    {
      label: 'Permenetly Delete',
      value: {
        id: 2,
        name: 'Permenetly Delete'
      }
    }
  ]

  constructor(
    private elem: ElementRef,
    public  extApi:  ExtapiService,) { 

    this.rowSelection = 'multiple';
  }
  
  async ngOnInit(): Promise<void> {

    try {

      var allUsers = await this.extApi.viewAllUsers({});

      this.activeRowData = allUsers.filter(item => item.status !== userStatus.Active); 

      this.activeRowData.forEach(aRow => {

        switch(aRow.role){
          case userRole.administrator: aRow.role = "Administrator"; break;
          case userRole.bulider: aRow.role = "Bulider"; break;
          case userRole.premium: aRow.role = "Premium"; break;
          case userRole.subscriber: aRow.role = "Subscriber"; break;
        }

        switch(aRow.status){
          case userStatus.Deleted:aRow.status = 'Deleted'; break;
          case userStatus.Banned: aRow.status = 'Banned'; break;
       
        }

      })

      this.filteredData = this.activeRowData;

      this.filteredDataCount = {
        Deleted: this.activeRowData.filter(aRow => aRow.status === 'Deleted').length,
        Banned: this.activeRowData.filter(aRow => aRow.status === 'Banned').length
      }

      this.gridFilter(this.gridFiletr.Deleted)

      
    }

    catch (error) {

      console.log(error);
      alert(error.statusText);
      
    }

   

  }

  gridFilter(filetr: IGridFilter2){

    let tabButtons = Array.from(this.elem.nativeElement.querySelectorAll('.filters'));

    tabButtons.forEach((tab: any) => {

      let btnId = tab.id;

      if(btnId === filetr)
        tab.classList.add('select');
      else
        tab.classList.remove('select');

    });

    switch(filetr){
      case this.gridFiletr.Deleted: 
      this.filteredData = this.activeRowData.filter(aRow => aRow.status === 'Deleted');
        this.filterTitle = "Deleted"; 
      break;

      case this.gridFiletr.Banned: 
        this.filteredData = this.activeRowData.filter(aRow => aRow.status === 'Banned');
        this.filterTitle = "Banned";
        break;

    }

  }

  userRenderer(params:any){

    let user = params.data.username
    // let userpic = params.data.pic
    let userpic = './assets/user.png'
 
    var ui = document.createElement('div');
    ui.style.display = "flex";
    ui.style.alignItems = "center";
    ui.style.justifyContent = "center";

    ui.innerHTML =
      `
        <img src = "${userpic}"/ style="width: 29px; height: 29px;">
        <div style = " margin-left: 14px;">${user} </div>
        
      `;

    return ui;

  }

  async applyBulkAction(){

    var selectedRows = this.deactiveUsers.api.getSelectedRows();

    console.log(selectedRows)

    if(selectedRows.length){

      if(this.selectedBulkAction){

        var action = this.selectedBulkAction.id;

        var ids = [];
  
        switch (action) {
  
          case userBulkActions.Recover:

            selectedRows.forEach(aRow => {
    
              ids.push(aRow.client_id)
              
  
            });

            try {

              await this.extApi.updateUsersStatus({id:ids,status:userStatus.Active});
              this.deactiveUsers.api.deselectAll();

              this.filteredData = this.filteredData.filter((x) =>{
                return selectedRows.indexOf(x) < 0;
              });

              this.deactiveUsers.api.redrawRows();

              this.selectedBulkAction = this.bulkActions[0];

              this.filteredDataCount = {
                Deleted: this.filteredData.filter(aRow => aRow.status === 'Deleted').length,
                Banned: this.filteredData.filter(aRow => aRow.status === 'Banned').length
              }
              
            } 

            catch (error) {

              console.log(error);
              alert(error.statusText);
              
            }
            
            break;
  
          case userBulkActions.Delete:
  
            selectedRows.forEach(aRow => {
  
              ids.push(aRow.client_id)
              
   
            });
  
            try {
  
              await this.extApi.deleteUser(ids);
              this.deactiveUsers.api.deselectAll();
              this.filteredData = this.filteredData.filter((x) =>{
              return selectedRows.indexOf(x) < 0;
              });
              this.deactiveUsers.api.redrawRows();
              this.selectedBulkAction = this.bulkActions[0];
              this.filteredDataCount = {
                Deleted: this.filteredData.filter(aRow => aRow.status === 'Deleted').length,
                Banned: this.filteredData.filter(aRow => aRow.status === 'Banned').length
              }
              
            } 
  
            catch (error) {
  
              console.log(error);
              alert(error.statusText);
              
            }

            break;
    
        }

      }

      else{

        alert("please select a action")

      } 
     
    }
    
    else{

      alert("please select the rows")
    }

  }

  async recoverUser(params){

    let rowData = params.data;

    await this.extApi.updateUsersStatus({id:[rowData.client_id], status:userStatus.Active});

    this.filteredData = this.filteredData.filter(el => el.client_id !== rowData.client_id)

    this.filteredDataCount = {
      Deleted: this.filteredData.filter(aRow => aRow.status === 'Deleted').length,
      Banned: this.filteredData.filter(aRow => aRow.status === 'Banned').length
    }


  }

  downloadXl(){

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Users');

    worksheet.columns = [
      { header: 'Client ID', key: 'client_id', width: 16 },
      { header: 'Username', key: 'username', width: 30 },
      { header: 'Full Name', key: 'full_name', width: 30 },
      { header: 'Email', key: 'email', width: 30 },
      { header: 'Role', key: 'role', width: 20 },
      { header: 'Account Status', key: 'status', width: 16 }
    ];

    worksheet.addRows(this.activeRowData, "n")

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, "deleted_banned_users.xlsx");
    })

  }


}
