<div class="add-new-container">

    <div class="main-title">
        <div class="title">Auctions > {{mainTitle}}</div>
        <div class="auc_id_wrapper">Auction ID_{{genaratedAUCID}}</div>
    </div>

    <div class="title-area">

        <div class="title">Name Of Auction House</div>

        <input type="text" pInputText placeholder="Type Here" [(ngModel)]="title">  
        
    </div>

    <div class="description-area">

        <div class="title">Add Description</div>

        <p-editor [(ngModel)]="description" [style]="{'height':'252px'}"></p-editor>

    </div>

    <div class="contract-area">

        <div class="left-panel">

            <div class="title">

                Contract Name
    
            </div>

            <input type="text" pInputText placeholder="Type Here" [(ngModel)]="contractName">

            <div>{{contractFileName}}</div>
            
        </div>


        <!-- <input type="file" (change)="onChange($event)" /> -->
        
        <!-- <p-editor [(ngModel)]="contract" [style]="{'height':'252px'}"></p-editor> -->

        <div class="right-panel">

            <div class="title">Upload Contract</div>

            <label  for="browse" class="upload-wrapper"  (drop)="dropContract($event)" (dragover)="allowDrop($event)"></label>
            <input id="browse" type="file" (change)="onChange($event)" hidden/>

        </div>

        

    </div>

    <div class="selection-area">

        <div class="category">

            <div class="title">Category</div>

            <p-dropdown [options]="auctions" [(ngModel)]="category" (click)="showEnterFeeAndNumberOfBiddingRooms()" ></p-dropdown>

        </div>

        <div class="enter-fee">

            <div class="title">Enter Fee</div>

            <img src="./assets/$.png" style="margin-right: 10px;">
            <input type="text" min="1" pInputText readonly  [(ngModel)]="enterFee"> 

        </div>

        <div class="bidding-rooms">

            <div class="title">Bidding Rooms</div>

            <input type="number" min="1" pInputText placeholder="Number of rooms" readonly [(ngModel)]="biddingRooms"> 

        </div>

        <div class="starting-bid">

            <div class="title">Starting Bid</div>

            <div class="amount">

                <img src="./assets/$.png" >

                <input type="number" min="1" pInputText placeholder="Enter amount" [(ngModel)]="startingBid"> 

            </div>

           

        </div>

        <div class="entrants">

            <div class="entrants-title-area">

                <div class="title">Entrants</div>

                <img src="./assets/icons_info 2.png">

            </div>

            <p-dropdown [options]="entrants" [(ngModel)]="entrantsAmount"></p-dropdown>

        </div>

        <div class="bid-interval" *ngIf="showBidInterval">

            <div class="title">Bid Interval</div>

            <p-dropdown [options]="bidIntervalOptions" [(ngModel)]="bidInterval"></p-dropdown> 

            <div class="note">Default set bid interval is 8 hours</div>

        </div>

        <div class="t-allowance">

            <div class="title">Add Token Allowance</div>

            <input type="number" pInputText placeholder="Enter amount" min="0"  [(ngModel)]="tokenAllowance"> 

        </div>

    </div>

    <div class="title" style="padding-left: 40px; margin-bottom: 24px;">Select a Template</div>
    <div class="template-area">

        <div class="content">
            
            <input type="checkbox" id="temp1" [checked]="selectedTemplate === 'template1'" (change)="selectTemplate('template1')">
            <label for="temp1">
                <img src="./assets/auction-templates/template-1.png">
            </label>


            <input type="checkbox" id="temp2" [checked]="selectedTemplate === 'template2'" (change)="selectTemplate('template2')">
            <label for="temp2">
                <img src="./assets/auction-templates/template-2.png">
            </label>


            <input type="checkbox" id="temp3" [checked]="selectedTemplate === 'template3'" (change)="selectTemplate('template3')">
            <label for="temp3">
                <img src="./assets/auction-templates/template-3.png">
            </label>


        </div>

    </div>
    
    <div class="upload-area">

        <div class="container">

            <div class="upload-btn">

                <label for="upload"><img [src]="image"/></label>

                <input
                type="file"
                (change)="updateImage($event)"
                style="display: none"
                accept="image/*"
                id="upload"
                hidden
                #selectImage
                />                                                          
                                
            </div>

        </div>
        

        
    </div>

    <div class="btn-area">

        <button type="button" style="background-color: #4A8998;" (click)="publish()">Publish</button>

        <button type="button" style="color: #4A8998;" (click)="draft()" [disabled]="title">Save Draft</button>

    </div>

</div>


<div *ngIf="clickChooseContract">
    <app-choose-contract-popup [display]="clickChooseContract"></app-choose-contract-popup>
</div>
