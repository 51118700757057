<div class="choose-contract-container">

    <p-dialog header="Choose and Select Contract" [(visible)]="display"  [modal]="true" position="center" [draggable]="false" (onHide)="close()" [transitionOptions]="'200ms'" [style.width]="'100%'" [style.height]="'100%'">
        
        <div class="choose-contract-content">

            <div class="section-1">
                <div class="ui-inputgroup">
                    <span class="ui-inputgroup-addon"><i class="pi pi-search"></i></span>
                    <input type="text" pInputText placeholder="Search for users">         
                </div>
            </div>

            <div class="section-2">Contracts Name</div>

            <div class="section-3">

                <div class="contracts" *ngFor="let contractsItm of contracts; let i = index">

                    <p-radioButton name="paymentMethods" [value]= "contractsItm" [(ngModel)]="selectedContract" (onClick)="setcontractDetails()"></p-radioButton> 
                    <div class="name">{{contractsItm.name}}</div>
                
                </div>

                <div *ngIf="contracts.length === 0">No Contracts</div>
            </div>

            <div class="section-4">

                <button (click)="choose()">Choose</button>

            </div>
            
        </div>
        

    </p-dialog>
</div>

