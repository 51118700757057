import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  @ViewChild('pwd') pwd: ElementRef;
  @ViewChild('email') email: ElementRef;
  @ViewChild('icon') icon: ElementRef;

  year;
  userEmail: string ='test@gmail.com';
  userPassword : string ='123456';

  // user: SocialUser;
  loggedIn: boolean;

  constructor(private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
  }

  login(){

    this.authService.login(this.userEmail, this.userPassword).then(async () =>{     
      
      this.router.navigate(['/home']);

    })

  }



}
