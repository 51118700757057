<div class="add-new-scope-container">

    <div class="title-area">

        <div class="title"> Contractors > Create Scope Of Work </div>

    </div>

    <div class="sub-title">Create a new Scope Of Work  </div>

    <div class="add-new-scope-content">

        <div class="input-area">

            <div class="input-titles">

                <div class="winner">Select Winner</div>

                <div class="id">Contract ID</div>

                <div class="projectManager">Project Manager</div>

                <div class="clientAddress">Client Address</div>

                <div class="estimatesDueDate">Estimates Due Date</div>

                <div class="serviceType">Service Type</div>
					
            </div>

            <div class="inputs">

                <p-dropdown [options]="winners" [(ngModel)]="role"></p-dropdown>
                
                <input type="text" size="30" pInputText [(ngModel)]="id">

                <input type="text" size="30" pInputText [(ngModel)]="projectManager">

                <input type="text" size="30" pInputText [(ngModel)]="clientAddress">

                <input type="text" pInputText  [(ngModel)]="estimatesDueDate" />

                <div class="serviceType-area">

                    <p-dropdown [options]="services" [(ngModel)]="serviceType"></p-dropdown>

                    <img src="./assets/contractors/plus.png">

                </div>

            </div>


        </div>

        <div class="bottom-area">


            <textarea class="textarea" placeholder="Describe your service..."></textarea>

            <div class="button-area"><img src="./assets/contractors/btn.png"></div>

            <div class="date-area">

                <div class="start-date">

                    <div class="date">Contract Start Date</div>
                    
                    <p-calendar [showIcon]="true" inputId="icon"></p-calendar>

                </div>

                <div  class="end-date">

                    <div class="date">Contract End Date</div>

                    <p-calendar [showIcon]="true" inputId="icon"></p-calendar>

                    

                </div>



            </div>

            <div class="input-area2">
                
                <div class="input-titles">

                    <div class="service-budget">Service Budget</div>
    
                    <div class="description">Add Privacy Description</div>

                    
                </div>

                <div class="inputs">

                    <input type="text" placeholder="$ 300,000">

                </div>

            </div> 


            <p-editor [(ngModel)]="description" [style]="{'height':'298px'}"></p-editor>

            <div class="button-area2">

                <button class="save" (click)="addUser()">Save</button>

                <button class="draft">Save as Draft</button>

                <button class="pdf">Generate  PDF</button>

            </div>


        </div>

    </div>

</div>
