import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { userRole } from 'src/app/app.types';
import { ExtapiService } from 'src/app/extapi.service';

import * as moment from 'moment';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  roles = [
    {
      label: 'Administrator',
      value: {
        id: 1,
        name: 'Administrator'
      }
    },
    {
      label: 'Subscriber',
      value: {
        id: 2,
        name: 'Subscriber'
      }
    },
    {
      label: 'Premium',
      value: {
        id: 3,
        name: 'Premium'
      }
    },
    {
      label: 'Builder',
      value: {
        id: 4,
        name: 'Builder'
      }
    }
  ]

  // accountDetails= [
  //   {
  //     label: 'Account Details',
  //     value: {
  //       id: 1,
  //       name: 'Account Details'
  //     }
  //   },
  //   {
  //     label: 'Address',
  //     value: {
  //       id: 2,
  //       name: 'Address'
  //     }
  //   }
  // ]

  userData;
  // selectedType;
  activeRowData;
  selectedRole;
  auctionsAttended;
  auctionsWon;
  memberSince;
  spentAmount;

  allRegistredAuctions


  constructor(
    public appService: AppService,
    public extapiService: ExtapiService
  ) { }

  async ngOnInit(): Promise<void> {

    // this.selectedType = this.accountDetails[0].value;
    this.userData = this.appService.profileData;

    let role = this.userData.role;

    switch(role){

      case  "Administrator": 
        this.userData.role = {id:userRole.administrator,name: "Administrator"};
        break;

      case "Bulider":
        this.userData.role = {id:userRole.bulider,name: "Bulider"};
        break;

      case "Premium":
        this.userData.role = {id:userRole.premium,name: "Premium"};
        break;

      case "Subscriber":
        this.userData.role =  {id:userRole.subscriber,name: "Subscriber"};
        break;
    }

    this.selectedRole = this.userData.role;


    this.allRegistredAuctions = await this.extapiService.getAllRegisteredAuctionsDetailsPerUser({clientId: this.userData.client_id});
    
    this.auctionsAttended = this.allRegistredAuctions.length;
    this.spentAmount = "$" + this.allRegistredAuctions.reduce((a, c) => {return a + c.enter_fee}, 0).toLocaleString();

    this.memberSince = moment(this.userData.date).format("MMM Do YYYY");

    let WinningDetailsArr = await this.extapiService.getWinningDetailsPerUser({client_id: this.userData.client_id});
    this.auctionsWon  = WinningDetailsArr.length;

  }

  // setRoom(){

  //   let idx = this.accountDetails.findIndex(el => el.value.id === this.selectedType.id);
  //   this.selectedType = this.accountDetails[idx].value;
  
  // }

  async changeUserRole(){
    
    await this.extapiService.updateUsersRole({client_id: this.userData.client_id,role:this.selectedRole.id});
    this.userData.role = this.selectedRole;
    
  }
  

}
