import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-winner-portal',
  templateUrl: './winner-portal.component.html',
  styleUrls: ['./winner-portal.component.scss']
})
export class WinnerPortalComponent implements OnInit {

  @Input() display: boolean = false;
  @Input() popupData;

  @Output() closePopup = new EventEmitter<any>();

  tableFields = tableFields;
  acttions = actions;

  constructor() { }

  ngOnInit(): void {
  }

  close(){

    this.display = false;
    this.closePopup.emit();

  }

}


const tableFields =  [
  {
    Requirements: {
      value: 'Upload a Deed Document'
    },
    Document: {
      value: ''
    },
    DateRequested: {
      value: 'Nov 16, 2016'
    },
    DateSubmitted: {
      value: 'Nov 16, 2016'
    }
  },
  {
    Requirements: {
      value: 'Upload a Utility Bill (5th year prior and current year)'
    },
    Document: {
      value: ''
    },
    DateRequested: {
      value: 'Nov 16, 2016'
    },
    DateSubmitted: {
      value: 'Nov 16, 2016'
    }
  },
  {
    Requirements: {
      value: 'Perform Title Search'
    },
    Document: {
      value: ''
    },
    DateRequested: {
      value: 'Nov 16, 2016'
    },
    DateSubmitted: {
      value: 'Nov 16, 2016'
    }
  },
  {
    Requirements: {
      value: 'Upload a Photo Id'
    },
    Document: {
      value: ''
    },
    DateRequested: {
      value: 'Nov 16, 2016'
    },
    DateSubmitted: {
      value: 'Nov 16, 2016'
    }
  },
  {
    Requirements: {
      value: 'Upload a  Mortgage Bill'
    },
    Document: {
      value: ''
    },
    DateRequested: {
      value: 'Nov 16, 2016'
    },
    DateSubmitted: {
      value: 'Nov 16, 2016'
    }
  },
  {
    Requirements: {
      value: 'Previous homeowners income tax return'
    },
    Document: {
      value: ''
    },
    DateRequested: {
      value: 'Nov 16, 2016'
    },
    DateSubmitted: {
      value: 'Nov 16, 2016'
    }
  }

]

const actions = ['Approve', 'Decline', 'Pending'];