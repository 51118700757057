import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
  selector: 'app-regular-dropdown',
  templateUrl: './regular-dropdown.component.html',
  styleUrls: ['./regular-dropdown.component.scss']
})
export class RegularDropdownComponent implements ICellRendererAngularComp {

  params;
  label: string;

  options =  [
    {
      label: 'Top',
      value: {
        id: 1,
        name: 'Top'
      }
    },
    {
      label: 'Bottom',
      value: {
        id: 2,
        name: 'Bottom'
      }
    }
  ];

  constructor() { }
  agInit(params): void {
    this.params = params;
    this.label = this.params.label || null;
  }

  refresh(params?: any): boolean {
    return true;
  }
  ngOnInit(): void {
  }

}
