<div class="settings-privacy-container">

    <div class="main-title">Privacy Settings</div>

    <div class="text">

        <p>
            As a website owner, you may need to follow national or international privacy laws. For example, you may need to create and display a Privacy Policy. If you already have a Privacy Policy page, please select it below. If not, please create one.
        </p>

        <p>The new page will include help and suggestions for your Privacy Policy. However, it is your responsibility to use those resources correctly, to provide the information that your Privacy Policy requires, and to keep that information current and accurate.</p>

        <p>The new page will include help and suggestions for your Privacy Policy. However, it is your responsibility to use those resources correctly, to provide the information that your Privacy Policy requires, and to keep that information current and accurate.</p>

    </div>

    <div class="add-container">

        <div class="title">Add Privacy Description</div>

        <p-editor [(ngModel)]="text" [style]="{'height':'1295px'}"></p-editor>

        <button>Save Changes</button>

    </div>

</div>


