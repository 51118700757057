import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { RegularGridPopupComponent } from 'src/app/ds-components/ag-grid/regular-popup/regular-popup.component';
import { AdminMainViews, AdminSubViews, ContractStatus, IGridFilter, contractBulkActions, dropdownItems } from '../../app.types';
import { AppService } from 'src/app/app.service';
import { ExtapiService } from 'src/app/extapi.service';
import { Router } from '@angular/router';
import { Interconnect, IMessageStream } from 'ng-interconnect';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss']
})
export class ContractsComponent implements OnInit{

  @ViewChild('agGrid', { static: true }) agGrid: AgGridAngular;

  private changeView: IMessageStream | Promise<IMessageStream>;
  _MainViews = AdminMainViews;
  _SubViews = AdminSubViews;

  // selectedIndex;
  rowSelection;
  gridFiletr = IGridFilter;
  option = dropdownItems;
  filteredData;
  filteredDataCount = {
    all: 15,
    active: 2,
    unpublished: 3,
    draft: 4,
    trash: 5
  }

  filterTitle = "All";

  activeColumnDefs = [

    {  
      headerName: '',
      field: 'check',
      width: 140,
      resizable: true,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      
    },
    {  
      headerName: 'Contract Name',
      field: 'name',
      width: 420,
      resizable: true,
      unSortIcon: true,
      sortable: true,
      sort: 'desc'
     
    },
    {  
      headerName: 'Status',
      field: 'status',
      width: 346,
      resizable: true,
      cellRenderer: this.statusRenderer
      
    },
    {  
      headerName: 'Submitted on',
      field: 'date',
      width: 250,
      resizable: true,
      
    },
    {
      headerName: '',
      field: 'action',
      width: 50,
      resizable: true,
      cellRendererFramework: RegularGridPopupComponent,
      cellRendererParams: {
        items:[
          {
            label: 'View Contract Details',
            id: 11,
            color:"rgba(74, 137, 152, 1)"
          },
          {
            label: 'Edit',
            id: 7
          },
          {
            label: 'Active',
            id: 8
          },
          {
            label: 'Unpublsih',
            id: 9
          },
          {
            label: 'Trash',
            id: 10
          }
        ],
        clickHandler: this.dropdownClickHander.bind(this)
          
      }
      
    }
  
  ];
  
  activeRowData = [];

  bulkActions = [
    {
      label: 'Bulk Actions'
    },
    {
      label: 'Active',
      value: {
        id: 1,
        name: 'Active'
      }
    },
    {
      label: 'Unpublsih',
      value: {
        id: 2,
        name: 'Unpublsih'
      }
    },
    {
      label: 'Delete',
      value: {
        id: 3,
        name: 'Delete'
      }
    }
  ]

  id;
  contractName;
  description;
  contractDescription;
  contract;
  contractFileName;

  default: boolean = true;
  addNew: boolean = false;
  openEdit: boolean = false;

  selectedRowData;
  selectedBulkAction;

  constructor(
    private elem: ElementRef,
    public appService: AppService,
    public  extApi:  ExtapiService,
    public router: Router,
    private cdr: ChangeDetectorRef,
    private interconnect: Interconnect,
    ) {

    this.rowSelection = 'multiple';

    this.changeView = interconnect.connectToListener('home/changeView', 'leftbar');
    if (this.changeView['then']) {
      this.changeView['then']((notifier) => this.changeView = notifier);
    }

  }

  async ngOnInit() {

    try {

      this.activeRowData = await this.extApi.viewAllContracts({});
      this.refresh();
      
    } 
    catch (error) {

      console.log(error);
      alert(error.statusText);
      
    }

    

  }

  // ngAfterViewInit(): void {

  //   setTimeout(() => {console.log(this.agGrid)})
    
  // }

  // onAuctionSelect(params){
  //   alert("clicked")
  // }

  gridFilter(filetr: IGridFilter){

    let tabButtons = Array.from(this.elem.nativeElement.querySelectorAll('.filters'));

    tabButtons.forEach((tab: any) => {

      let btnId = tab.id;

      if(btnId === filetr)
        tab.classList.add('select');
      else
        tab.classList.remove('select');

    });

    switch(filetr){
      case this.gridFiletr.all: 
        this.filteredData = this.activeRowData; 
        this.filterTitle = "All"; 
      break;

      case this.gridFiletr.active: 
        this.filteredData = this.activeRowData.filter(aRow => aRow.status === 'Active');
        this.filterTitle = "Active";
        break;

      case this.gridFiletr.draft: 
        this.filteredData = this.activeRowData.filter(aRow => aRow.status === 'Draft');
        this.filterTitle = "Draft";
        break;

      case this.gridFiletr.unpublished: 
        this.filteredData = this.activeRowData.filter(aRow => aRow.status === 'Unpublish'); 
        this.filterTitle = "Unpublished";
        break;
      case this.gridFiletr.trash: 
        this.filteredData = this.activeRowData.filter(aRow => aRow.status === 'Trash'); 
        this.filterTitle = "Trash";
        break;
    }

  }

  statusRenderer(params:any){

    let status = params.data.status
 
    var ui = document.createElement('div');

    switch (status) {

      case "Unpublish":
        ui.style.color = '#EE212E';
      break;

      case "Active":
        ui.style.color = '#64C8BC';
      break;

      case "Trash":
        ui.style.color = '#616161';
      break;
        
      default:
        ui.style.color = '#6554C0';
      break;

    }

    ui.innerHTML =
      `
        <div style=" text-align: center;">${status}</div>
        
      `;

    return ui;

  }

  onRowSelected(params) {

    var x = params.api.getSelectedRows();
  
  }

  onCellClicked(params) {

    if (params.event.target.dataset.action == 'toggle' && params.column.getColId() == 'action') {

      const cellRendererInstances = params.api.getCellRendererInstances({
        rowNodes: [params.node],
        columns: [params.column],
      });

      if (cellRendererInstances.length > 0) {
        const instance = cellRendererInstances[0].getFrameworkComponentInstance();
        instance.togglePopup();
      }

    }

    this.selectedRowData = params.data;
    this.id = this.selectedRowData.contract_id;
    this.contractName = this.selectedRowData.name;
    this.contractDescription = decodeURIComponent(escape(atob(this.selectedRowData.description)));
    this.description = this.contractDescription

  }

  openNew(){

    this.addNew = true;
    this.openEdit = false;
    this.default = false;
  }

  async publishContract(){

    try {

      var date =new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000).toJSON().slice(0, 19).replace('T', ' ');

      if(this.contractName && this.contract){

        var newContract ={
          name:this.contractName,
          description: this.contract,
          status:ContractStatus.Active,
          date: date
        }
    

        if(!this.id){

         
          let addContract = await this.extApi.addNewContract(newContract);
        }

        else{

          var editContract = Object.assign({},newContract,{id:this.id});
    
          let addAuction = await this.extApi.updateContract(editContract);
        }

        this.activeRowData = await this.extApi.viewAllContracts({});
        this.refresh();
        this.agGrid.api.redrawRows();

        this.contractName = '';
        this.description = '';
        this.addNew = false;
        this.openEdit = false;
        this.default = true;
        


      }

      else{

        alert("fill all the fields");

      }
      
    } 
    
    catch (error) {

      console.log(error);
      alert(error.statusText);
      
    }
  
  }

  async saveDraftContract(){

    try {

      
      if(this.contractName && this.description){

        var date =new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000).toJSON().slice(0, 19).replace('T', ' ');

        var newContract ={
          name:this.contractName,
          description: window.btoa(unescape(encodeURIComponent(this.description))),
          status:ContractStatus.Draft,
          date: date
        }

        
        if(!this.id){

         
          let addContract = await this.extApi.addNewContract(newContract);
        }

        else{

          var editContract = Object.assign({},newContract,{id:this.id});
    
          let addAuction = await this.extApi.updateContract(editContract);
        }

        this.activeRowData = await this.extApi.viewAllContracts({});
        this.refresh();
        this.agGrid.api.redrawRows();

        this.contractName = '';
        this.description = '';
        this.addNew = false;
        this.openEdit = false;
        this.default = true;

      }
      
    } 
    
    catch (error) {

      console.log(error);
      alert(error.statusText);
      
    }

  }


  async dropdownClickHander(params:dropdownItems) {

    switch (params) {

      case this.option.Edit:
        this.default= false;
        this.addNew = false;
        this.openEdit= true;
        break;


      case this.option.Active:
        var data = this.selectedRowData;
        data.status = ContractStatus.Active;
        try {

          await this.extApi.updateContractStatus({data});
          
        } 
        catch (error) {

          console.log(error);
          alert(error.statusText);
          
        }
        
        
        break;


      case this.option.Unpublish:
        var data = this.selectedRowData;
        data.status = ContractStatus.Unpublished;
        try {

          await this.extApi.updateContractStatus({data});
          
        } 
        catch (error) {

          console.log(error);
          alert(error.statusText);
          
        }
        
        break;
    

      case this.option.Trash:
        var data = this.selectedRowData;
        data.status = ContractStatus.Trash;
        try {

          await this.extApi.updateContractStatus({data})
          
        } 
        catch (error) {

          console.log(error);
          alert(error.statusText);
          
        }
        break;
    

      default:
        break;

    }

    this.refresh();
    this.agGrid.api.redrawRows();

    
  }

  async refresh(){

    this.activeRowData.forEach(aRow => {

      switch(aRow.status){
        case ContractStatus.Active: aRow.status = "Active"; break;
        case ContractStatus.Draft: aRow.status = "Draft"; break;
        case ContractStatus.Trash: aRow.status = "Trash"; break;
        case ContractStatus.Unpublished: aRow.status = "Unpublish"; break;
      }

    })

    this.filteredData = this.activeRowData;

    this.filteredDataCount = {
      all: this.activeRowData.length,
      active: this.activeRowData.filter(aRow => aRow.status === 'Active').length,
      unpublished: this.activeRowData.filter(aRow => aRow.status === 'Unpublish').length,
      draft: this.activeRowData.filter(aRow => aRow.status === 'Draft').length,
      trash: this.activeRowData.filter(aRow => aRow.status === 'Trash').length
    }

  }

  userChangeView(viewId, subViewId = undefined){

    (this.changeView as IMessageStream).emit({mainView: viewId, subView: subViewId});

  }

  async applyBulkAction(){

    var selectedRows = this.agGrid.api.getSelectedRows();

    
    if(selectedRows.length){

      if(this.selectedBulkAction){

        var action = this.selectedBulkAction.id;

        var ids = [];
  
        switch (action) {
  
          case contractBulkActions.Active:

            selectedRows.forEach(aRow => {
    
              ids.push(aRow.contract_id)
              
  
            });

            try {
    
              await this.extApi.updateContractStatus({data:{contract_id:ids,status: ContractStatus.Active}});
              this.activeRowData = await this.extApi.viewAllContracts({});
              this.refresh();
              this.agGrid.api.deselectAll();
              this.agGrid.api.redrawRows();
              this.selectedBulkAction = this.bulkActions[0];
              
            } 

            catch (error) {

              console.log(error);
              alert(error.statusText);
              
            }

            break;


          case contractBulkActions.Unpublished:

            selectedRows.forEach(aRow => {
    
              ids.push(aRow.contract_id)
              

            });

            try {
    
              await this.extApi.updateContractStatus({data:{contract_id:ids,status: ContractStatus.Unpublished}});
              this.activeRowData = await this.extApi.viewAllContracts({});
              this.refresh();
              this.agGrid.api.deselectAll();
              this.agGrid.api.redrawRows();
              this.selectedBulkAction = this.bulkActions[0];
              
            } 

            catch (error) {

              console.log(error);
              alert(error.statusText);
              
            }
            break;

  
          case contractBulkActions.Delete:
  
            selectedRows.forEach(aRow => {
  
              ids.push(aRow.contract_id)
              
   
            });
  
            try {
  
              await this.extApi.deleteContracts({id:ids});
              this.agGrid.api.deselectAll();
              this.filteredData = this.filteredData.filter((x) =>{
              return selectedRows.indexOf(x) < 0;
              });
              this.activeRowData = await this.extApi.viewAllContracts({});
              this.refresh();
              this.agGrid.api.redrawRows();
              this.selectedBulkAction = this.bulkActions[0];
              
            } 
  
            catch (error) {
  
              console.log(error);
              alert(error.statusText);
              
            }
            break;
    
        }

        ids = [];

      }

      else{

        alert("please select a action")

      } 
     
    }
    
    else{

      alert("please select the rows")
    }

  }

  async uploadFile(e){

    this.contract= await this.convertBase64(e.target.files[0]);
    this.contractFileName = e.target.files[0].name;

  }

  private convertBase64 = (file) => {

    return new Promise((resolve, reject) => {

        const fileReader = new FileReader();

        fileReader.readAsDataURL(file);

        fileReader.onload = () => {

            resolve(fileReader.result.toString());
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    });
  };

}
