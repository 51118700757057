<div class="container">

    <div class="profile">

        <div class="pro-pic">
            <img class="pic" src="./assets/profilepic.png">
            <img class="icon" src="./assets/profile/edit.png">
        </div>

        <div class="name">
            William Fancyson
        </div>

        <div class="username">
            @thomasdox
        </div>

        <div class="joined">
            Join on 24 March 2017
        </div>

        <div class="address">
            St George's Ln Singapore
        </div>
    </div>

    <div class="details">

        <div class="item">

            <div class="icon-area">

                <img src="./assets/profile/icons/entries.png">

                <label>
                    Entries:
                </label>

            </div>

            <div class="val">
                2
            </div>

        </div>

        <div class="item">

            <div class="icon-area">

                <img src="./assets/profile/icons/bids.png">

                <label>
                    Bids Placed:
                </label>
                
            </div>

            <div class="val">
                2
            </div>

        </div>

        <div class="item">

            <div class="icon-area">

                <img src="./assets/profile/icons/coins.png">

                <label>
                    Contracts Won:
                </label>
                
            </div>

            <div class="val">
                0
            </div>

        </div>

        <div class="item">

            <div class="icon-area">

                <img src="./assets/profile/icons/cash.png">

                <label>
                    Cash Spent:
                </label>
                
            </div>

            <div class="val">
                0
            </div>

        </div>

        <div class="item">

            <div class="icon-area">

                <img src="./assets/profile/icons/entries.png">

                <label>
                    Successful Invites
                </label>
                
            </div>

            <div class="val diff">
                2
            </div>

        </div>

    </div>

</div>
