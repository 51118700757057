import { Component} from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'grid-regular-checkbox',
  template: `
    <button style ="border:none; background: transparent; cursor: pointer;">

        <img src="./assets/contractors/view.png"  style="width: 24px; height: 24px;"/>

    </button>
  `
})
export class viewBtnComponent implements ICellRendererAngularComp {

  params: any;

  constructor(
  ) {

  }

  agInit(params: any): void {
    this.params = params;
  }

//   recoverUser(event) {

//     event.stopPropagation();
  
//     if(this.params.clickHandler)
//       this.params.clickHandler(this.params)

//   }



  refresh(params?: any): boolean {

    this.params = params;

    return true;
  }

}
