<div class="contractor-container">
    <p-dialog [(visible)]="display" position="top" [draggable]="false" (onHide)="close()" [transitionOptions]="'200ms'" [style.width]="'100%'" [style.height]="'100%'">

        <div class="contractor-content">

            <div class= "left-panel">

                <div class="logo">
                    <img src="./assets/LOGO@3xDecision 2 1.png">
                </div>
            
                <div id="0" class="main-btns select" >
                    <!-- <img [src]="overviewBtn"> -->
                    <label>Messages</label>
                </div>
            
                <div id="1" class="main-btns">
                    <!-- <img [src]="auctionsBtn"> -->
                    <label>Scope of Work</label>
                </div>
        
                <div class="main-btn-options">
                    
                    <div id="10" class="option-btn opt-btn-select">
                        Create
                    </div>
        
                    <div  id="11" class="option-btn">
                        Saved
                    </div>
        
        
                </div>

                <div id="1" class="main-btns">
                    <!-- <img [src]="auctionsBtn"> -->
                    <label>DS Contracts</label>
                </div>
        
                <div class="main-btn-options">
                    
                    <div id="10" class="option-btn opt-btn-select">
                        Project Board
                    </div>
        
                    <div  id="11" class="option-btn">
                        Bidding Board
                    </div>
        
        
                </div>
            </div>

            <div class="middle-area">middle area</div>

        
        </div>

    </p-dialog>

</div>
