import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';


@Injectable({
  providedIn: 'root'
})
export class ExtapiService {

  constructor(
    public http:HttpClient,
    private appConfig: AppConfigService
  ) { }


  //auctions

  addNewAuction(requestFields:object){
    
    return  this.http.post<any>(this.appConfig.getBaseUrl('api') + '/auction/createAuction', requestFields).toPromise();
    
  }

  viewAllAuctions(requestFields:object): Promise<any>{
    
    return  this.http.post<any>(this.appConfig.getBaseUrl('api') + '/auction/viewAllAuctions', requestFields).toPromise();
    
  }

  updateAuctionStatus(requestFields:object): Promise<any>{
    
    return  this.http.post<any>(this.appConfig.getBaseUrl('api') + '/auction/updateAuctionStatus', requestFields).toPromise();
    
  }

  updateAuction(requestFields:object): Promise<any>{
    
    return  this.http.post<any>(this.appConfig.getBaseUrl('api') + '/auction/updateAuction', requestFields).toPromise();
    
  }

  deleteAuctions(requestFields:object): Promise<any>{
    
    return  this.http.post<any>(this.appConfig.getBaseUrl('api') + '/auction/deleteAuctions', requestFields).toPromise();
    
  }

  getAllRegisteredAuctionsDetailsPerUser(requestFields:object): Promise<any>{
    return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/register/getAllRegisteredAuctionsDetailsPerUser', requestFields).toPromise();
  }

  //contract
  addNewContract(requestFields:object){
    
    return  this.http.post<any>(this.appConfig.getBaseUrl('api') + '/contract/createContract', requestFields).toPromise();
    
  }

  viewAllContracts(requestFields:object): Promise<any>{
    
    return  this.http.post<any>(this.appConfig.getBaseUrl('api') + '/contract/viewAllContracts', requestFields).toPromise();
    
  }

  updateContractStatus(requestFields:object): Promise<any>{
    
    return  this.http.post<any>(this.appConfig.getBaseUrl('api') + '/contract/updateContractStatus', requestFields).toPromise();
    
  }

  updateContract(requestFields:object): Promise<any>{
    
    return  this.http.post<any>(this.appConfig.getBaseUrl('api') + '/contract/updateContract', requestFields).toPromise();
    
  }

  deleteContracts(requestFields:object): Promise<any>{
    
    return  this.http.post<any>(this.appConfig.getBaseUrl('api') + '/contract/deleteContract', requestFields).toPromise();
    
  }

  //winners
  viewAllWinners(requestFields:object): Promise<any>{
    
    return  this.http.post<any>(this.appConfig.getBaseUrl('api') + '/winners/viewAllWinners', requestFields).toPromise();
    
  }

  updateWinnersStatus(requestFields:object): Promise<any>{
    return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/winners/updateClaimeStatus', requestFields).toPromise();
  }

  getWinningDetailsPerUser(requestFields:object): Promise<any>{
    return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/winners/getWinningDetailsPerUser', requestFields).toPromise();
  }


  //ads
  addNewAd(requestFields:object): Promise<any>{
    
    return  this.http.post<any>(this.appConfig.getBaseUrl('api') + '/ads/createAd', requestFields).toPromise();
    
  }

  viewAllAds(requestFields:object): Promise<any>{
    
    return  this.http.post<any>(this.appConfig.getBaseUrl('api') + '/ads/viewAllAds', requestFields).toPromise();
    
  }

  deleteAds(requestFields:object): Promise<any>{
    
    return  this.http.post<any>(this.appConfig.getBaseUrl('api') + '/ads/deleteAd', requestFields).toPromise();
    
  }

  updateAdsStatus(requestFields:object): Promise<any>{
    
    return  this.http.post<any>(this.appConfig.getBaseUrl('api') + '/ads/updateAdsStatus', requestFields).toPromise();
    
  }

  //users

  addNewUser(requestFields:object): Promise<any>{
    
    return  this.http.post<any>(this.appConfig.getBaseUrl('api') + '/user/addUser', requestFields).toPromise();
    
  }

  viewAllUsers(requestFields:object): Promise<any>{
    
    return  this.http.post<any>(this.appConfig.getBaseUrl('api') + '/user/viewAllUsers', requestFields).toPromise();
    
  }

  deleteUser(requestFields:object): Promise<any>{
    
    return  this.http.post<any>(this.appConfig.getBaseUrl('api') + '/user/deleteUsers', requestFields).toPromise();
    
  }

  updateUsersStatus(requestFields:object): Promise<any>{
    
    return  this.http.post<any>(this.appConfig.getBaseUrl('api') + '/user/updateUsersStatus', requestFields).toPromise();
    
  }

  updateUsersRole(requestFields:object): Promise<any>{
    
    return  this.http.post<any>(this.appConfig.getBaseUrl('api') + '/user/updateUsersRole', requestFields).toPromise();
    
  }

  getSoftwareUsage(): Promise<any>{

    return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/visitors/stats', {}).toPromise();

  }

  
  getMoneyUsage(): Promise<any>{

    return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/stats/getMoneyUsage', {}).toPromise();

  }

  getPeformences(payload): Promise<any>{

    return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/stats/getPeformences', payload).toPromise();

  }

  getNumberOfUsers(payload): Promise<any>{

    return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/stats/getNumberOfUsers', payload).toPromise();

  }

  getClientDetails(payload): Promise<any>{

    return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/user/getUserDetailsFromId', payload).toPromise();

  }
  
  createToken(payload): Promise<any>{
    return this.http.post<any>(this.appConfig.getBaseUrl('auth') + '/createStaffToken', payload).toPromise();
  }

  





 




}
