<div class="msg-container">

    <div class="content">

        <div class="inbox">

            <div class="header">

                <div class="title-area">

                    <div class="main-title">Inbox</div>

                    <div class="sub-title">(98 messages)</div>

                </div>

                <div class="btn-area">

                    
                    <button>+ New</button>
                    
                    <div>
                        <img src="/assets/Filter.png">
                    </div>

                </div>

            </div>

            <div class="messages">

                <app-notification></app-notification>
                
            </div>
        </div>

        <div class="message-area">

            <div class="title-area">

                <div>
                    <img src="./assets/profilepic.png">
                </div>

                <div>
                    
                    <div class="title">William Fancyson</div>

                    <div class="status">

                        <img src="./assets/message/Online_status.png" style="height: 16px; width: 16px; margin-right: 0px;">
                        
                        Online
                        
                    </div>


                </div>


            </div>

            <div class="messages">

                <div class="msg-container">

                    <div class="in-msg">By the way, can you please show me the preview of your new product?</div>

                    <div class="time">12:46 PM</div>
                    
                </div>

                <div class="msg-container">

                    <div class="out-msg-container">

                        <div class="out-msg">

                                
                            <div style="margin-bottom: 5px;"> Sure 😃</div>

                            <img src="./assets/message/cat.png">

                            <img src="./assets/message/bird.png">

                        </div>

                    </div>

                    <div class="time-out">12:46 PM
                        <img src="./assets/message/Checkmark _Double.png" style="width: 16.67px; height: 10.83px;">
                    </div>

                </div>

                <div class="msg-container">

                    <div class="in-msg">Yeah, your iconset are very awesome and usefull for my web app. Great job! 👏🏽.</div>

                    <div class="time">12:46 PM</div>

                </div>
            </div>

            <div>

                <input id="m" placeholder="Type a messages…" autocomplete="off" required />

                <button>
                    
                    <img src="./assets/message/Icon _send.png" alt="">

                </button>

                <div class="icon1">

                    <img src="./assets/message/Add.png">

                </div>

                <div class="icon2">

                    <img src="./assets/message/Add2.png">

                </div>

            </div>

        </div>

    </div>

    <div class="right-panal">

        <app-right-panal></app-right-panal>
        
    </div>
  

</div>
