<div>

  <i #trigger class="pi pi-ellipsis-v" data-action="toggle"></i>

  <div #content *ngIf="isOpen" class="menu-container">

    <div class="menu-item" *ngFor="let option of options" >

      <div [style.color]="option.color" (click)="checkedHandler(option.label)" >{{option.label}}</div>
      
    </div>
  
  </div>

</div>