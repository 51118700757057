import { Component, OnInit } from '@angular/core';
import { IMessageStream, Interconnect } from 'ng-interconnect';
import { AdminMainViews, AdminSubViews } from 'src/app/app.types';
import { ExtapiService } from 'src/app/extapi.service';

@Component({
  selector: 'app-create-scope',
  templateUrl: './create-scope.component.html',
  styleUrls: ['./create-scope.component.scss']
})
export class CreateScopeComponent implements OnInit {

  private changeView: IMessageStream | Promise<IMessageStream>;
  _MainViews = AdminMainViews;
  _SubViews = AdminSubViews;

  username;
  projectManager;
  clientAddress;
  id;
  estimatesDueDate;
  role;
  status;
  serviceType;
  description;

  winners = [
    {
      label: 'Tam Luong',
      value: {
        id: 1,
        name: 'Tam Luong'
      }
    },
    {
      label: 'Sang Le',
      value: {
        id: 2,
        name: 'Sang Le'
      }
    }
  ]

  services = [
    {
      label: 'Wood working',
      value: {
        id: 1,
        name: 'Wood working'
      }
    }
  ]

  constructor(
    public extApi:  ExtapiService,
    private interconnect: Interconnect

  ) { 

    this.changeView = interconnect.connectToListener('home/changeView', 'leftbar');
    if (this.changeView['then']) {
      this.changeView['then']((notifier) => this.changeView = notifier);
    }
  }

  ngOnInit(): void {

    this.role = this.winners[0].value;
    // this.services = this.services[0].value;

  }

  async addUser(){

    // try {

    //   if(this.username && this.email){

    //     var date =new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000).toJSON().slice(0, 19).replace('T', ' ');

    //     let user = {

    //       username:this.username,
    //       firstName:this.firstName,
    //       lastName:this.lastName,
    //       fullName:this.firstName +" "+this.lastName,
    //       email:this.email,
    //       password:this.password,
    //       role:this.role.id,
    //       status:userStatus.Active,
    //       date: date

    //     }

    //     let newUser = await this.extApi.addNewUser({user});

    //     this.username = '';
    //     this.firstName = '';
    //     this.lastName = '';
    //     this.email = '';
    //     this.password = '';
    //     this.role = this.roles[0].value;
    //     this.userChangeView();

    //   }

    //   else {

    //     alert("Fill all fields")
        
    //   }
      
    // } 
    
    // catch (error) {

    //   console.log(error);
    //   alert(error.statusText);
      
    // }   

  }

  userChangeView(){

    (this.changeView as IMessageStream).emit({mainView: AdminMainViews.users, subView: AdminSubViews.all_users});

  }

}

