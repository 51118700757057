<div class="home">

  
  <div class="left-panal">
    <app-left-panal></app-left-panal>
  </div>

  <div class="content">

    <div class="top-bar">
      <app-top-bar></app-top-bar>
    </div>

    <div class="client-area">

      <app-overview *ngIf="currentMainView === _MainViews.overview"></app-overview>

      <app-auctions *ngIf="currentMainView === _MainViews.auctions || currentMainView === _SubViews.act_all"></app-auctions>
      <app-auction-add-new *ngIf=" currentMainView === _SubViews.act_add_new"></app-auction-add-new>
      <app-contracts *ngIf=" currentMainView === _SubViews.act_contracts"></app-contracts>
      <app-winners *ngIf="currentMainView === _SubViews.act_winners"></app-winners>

      <app-users *ngIf="currentMainView === _SubViews.user_management" ></app-users>
      <app-user-analytics *ngIf="currentMainView === _SubViews.user_analytics"></app-user-analytics>
      <app-all-users *ngIf="currentMainView === _MainViews.users || currentMainView === _SubViews.all_users"></app-all-users>
      <app-add-new-users *ngIf="currentMainView === _SubViews.usr_add_new"></app-add-new-users>
      <app-user-profile *ngIf="currentMainView === _SubViews.user_profile"></app-user-profile>

      <app-message *ngIf="currentMainView === _MainViews.message ||currentMainView === _SubViews.msg_builder_side"></app-message>
      <app-mass-msg *ngIf=" currentMainView === _SubViews.msg_mass_msg"></app-mass-msg>
      <app-client-side *ngIf=" currentMainView === _SubViews.msg_client_side"></app-client-side>

      <app-ads *ngIf="currentMainView === _MainViews.ads || currentMainView === _SubViews.ads_new"></app-ads>
      <app-ads-all *ngIf="currentMainView === _SubViews.ads_all"></app-ads-all>

      <app-archive *ngIf="currentMainView === _MainViews.archive"></app-archive>

      <app-settings *ngIf="currentMainView === _MainViews.settings || currentMainView === _SubViews.set_general"></app-settings>
      <app-settings-permalink  *ngIf="currentMainView === _SubViews.set_permalink"></app-settings-permalink>
      <app-settings-privacy *ngIf="currentMainView === _SubViews.set_privacy"></app-settings-privacy>

      <app-profile *ngIf="currentMainView === _MainViews.profile"></app-profile>


      <app-contractors *ngIf="currentMainView === _SubViews.con_save_scope"></app-contractors>
      <app-create-scope *ngIf="currentMainView === _MainViews.contractors || currentMainView === _SubViews.con_create_scope"></app-create-scope>
      <app-project-board *ngIf="currentMainView === _SubViews.con_project_board"></app-project-board>
      <app-bidding-board *ngIf="currentMainView === _SubViews.con_bidding_board"></app-bidding-board>

    </div>

  </div>

</div>

<div *ngIf="showContractors">
  <app-contractors-popup [display]="showContractors" ></app-contractors-popup>

</div>


  
  