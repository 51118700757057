import { Component, Input, OnInit } from '@angular/core';
import { Interconnect } from 'ng-interconnect';

@Component({
  selector: 'app-contractors-popup',
  templateUrl: './contractors-popup.component.html',
  styleUrls: ['./contractors-popup.component.scss']
})
export class ContractorsPopupComponent implements OnInit {

  @Input() display: boolean = false;
  broadcaster

  constructor(private interconnect: Interconnect) {

    this.broadcaster = this.interconnect.createBroadcaster('contractor-popup');
   }

  ngOnInit(): void {
  }

  close(){

    this.display = false;
    this.broadcaster.emit(false)

  }

}
