<div class="contractor-container">

    <div class="title-area">

        <div class="title">Contractors > Saved Scope Of Work</div>

    </div>

    <div class="contractor-content">

        <div class="tab-area">

            <div [id]="gridFiletr.all" class="filters select" style="width: 135px;" (click)="gridFilter(gridFiletr.all)">All ({{filteredDataCount.all}})</div>
            <div [id]="gridFiletr.draft" class="filters" style="width: 150px;" (click)="gridFilter(gridFiletr.draft)">Draft ({{filteredDataCount.draft}})</div>
            <div [id]="gridFiletr.trash" class="filters" style="width: 150px; border-right: unset;" (click)="gridFilter(gridFiletr.trash)">Trash ({{filteredDataCount.trash}})</div>

        </div>

        <div class="dropdownbtn-area">
            <p-dropdown [options]="bulkActions" [(ngModel)]="selectedBulkAction"></p-dropdown>

            <button type="button" (click)="applyBulkAction()">Apply</button>
        </div>
        
    </div>

    <div class="table-area">

        <div class="common-ag-grid-styles" style="width: 100%; height: 100%;">
                    
            <ag-grid-angular #agGrid 
                style="width: 100%; height: 100%; font-size: 12px;" 
                class="ag-theme-alpine"
                [rowData]="filteredData"
                [columnDefs]="activeColumnDefs"
                [suppressRowClickSelection]="true"
                [rowSelection]="rowSelection"
                (cellClicked)="onCellClicked($event)"
            >
            </ag-grid-angular>

        </div>

    </div>


</div>
