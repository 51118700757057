<div class="main-msg-container">

    <div class="content">

        <div class="inbox">

            <div class="header">

                <div class="title-area">

                    <div class="main-title">Inbox</div>

                    <div class="sub-title">(98 messages)</div>

                </div>

                <div class="btn-area">

                    
                    <button>+ New</button>
                    
                    <div>
                        <img src="/assets/Filter.png">
                    </div>

                </div>

            </div>

            <div class="messages">

                <div class="notify-container" *ngFor="let notificationItem of messages; let i = index;" (click)="openbox(i); notificationItem.msgCount = 0; notificationItem.isRead = false;">

                    <div class="notification-image">
                
                        <img [src]="notificationItem.userImage">
                
                    </div>
                
                    <div class="notification-info">
                
                        <div class="text">
                
                            <div class="name">{{notificationItem.username}}</div> 
                
                            <div class="message">{{notificationItem.msgs.length ? notificationItem.msgs[notificationItem.msgs.length - 1].display: '...'}}</div>
                
                        </div>
                
                        <div class="msg-count">
                            <div class="time" *ngIf="notificationItem.msgs.length">{{notificationItem.msgs[notificationItem.msgs.length - 1].datetime}}</div>
                            <div *ngIf="!notificationItem.isRead && notificationItem.msgCount" class="count">{{notificationItem.msgCount}}</div>
                        </div>
                
                    </div>
                    
                
                </div>
                
            </div>

        </div>

        <ng-container *ngFor="let notificationItem of messages; let i = index">

            <div class="message-area" *ngIf="currentItem == i">

                <div class="title-area">

                    <div>
                        <img [src]="notificationItem.userImage">
                    </div>

                    <div>
                        
                        <div class="title">{{notificationItem.username}}</div>

                        <div class="status">

                            <img src="./assets/message/Online_status.png" style="height: 16px; width: 16px; margin-right: 0px;">
                            
                            Online
                            
                        </div>


                    </div>


                </div>

                <div class="messages" #one>
                    
                </div>

                <div class="input-area">

                    <input (keyup.enter)="sendMessage()" id="m"  [(ngModel)]="text" placeholder="Type a messages…" autocomplete="off" required />

                    <div class="image-priview-area" *ngIf="uplodedFileContents.length">

                        <div class="image-priview-container" *ngFor="let imageContent of uplodedFileContents; let i = index;" >

                            <div class="image-area">
                                
                                <img [src]="imageContent.src"/>

                                <i class="pi pi-times-circle" (click)="removeImage(i)"></i>

                            </div>

                            <div class="file-name">{{imageContent.name}}</div>

                        </div>

                    </div>

                    <div class="btn-area">
                       
                        <div class="upload">

                            <div class="icon1">
        
                                <label for="img"><img src="./assets/message/Add.png"></label>
                                <input (change)="uploadFile($event)" style="height: 0px;" type="file" id="img" name="img">
            
                            </div>
            
                            <div class="icon2">
            
                                <img src="./assets/message/Add2.png">
            
                            </div>

                        </div>


                        <div class="send">

                            <button (click)="sendMessage()">
                        
                                <img src="./assets/message/Icon _send.png" alt="">
            
                            </button>

                        </div>


                    </div>

                </div>

            </div>

        </ng-container>

        
    </div>

    <div class="right-panal">

        <app-right-panal></app-right-panal>
        
    </div>
  

</div>
