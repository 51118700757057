<div class="overview-container">

    <div class="activity-container">

        <div class="chart">

            <div class="dropdown-area">

                <div>
                    <p-dropdown [options]="performances" [(ngModel)]="selectedOne" (onChange)="setChartPeriod()"></p-dropdown>
                </div>

                <div style="display: flex;">

                    <input type="month" [(ngModel)]="filterMonth" (change)="setChartPeriod()">
                    <div *ngIf="selectedOne.id !== 2" style="width: 10px;"></div>
                    <p-dropdown class="periods" *ngIf="selectedOne.id !== 2" [options]="selectedPeriod" [(ngModel)]="selectedperi" (onChange)="setChartPeriod()"></p-dropdown>

                    
                </div>

            </div>

            <p-chart #chart type="bar" [data]="selectedChartData" [width]="chartWidth" [responsive]="false" [height]="chartHeight" [options]="chartOptions"></p-chart>

        </div>

        <div class="details-container">

            <div class="details-container-title">{{title}}</div>

            <div class="dropdown-area">
                
            </div>

            <div class="template-area">

                <div class="details-content" *ngFor="let detailContents of allTimeUsage">

                    <div class="title">{{detailContents.title}}</div>
            
                    <div class="count">{{detailContents.count}}</div>
            
                </div>

            </div>   

        </div>

    </div>

    <div class="performace-container">

        <div class="performace-title-area">

            <div class="performace-title"> Performance</div>

            <div class="dropdown-area">

                <p-dropdown [options]="performances" [(ngModel)]="selectedPerformancePeriod" (onChange)="setPerformancePeriod(selectedPerformancePeriod)"></p-dropdown>
                
            </div>
            
        </div>

        <div class="performace-content">

            <div class="details-content" *ngFor="let detailContents of periodicallyUsage">

                <div class="title">{{detailContents.title}}</div>
        
                <div class="count">{{detailContents.count}}</div>
        
            </div>

        </div>

      
    </div>

</div>