import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { AUCCategories, AUCStatus, IGridFilter, auctionBulkActions, dropdownItems, AdminMainViews, AdminSubViews } from '../app.types';
import { RegularGridPopupComponent } from '../ds-components/ag-grid/regular-popup/regular-popup.component';
import { ExtapiService } from '../extapi.service';
import { Interconnect, IMessageStream } from 'ng-interconnect';
import { AppService } from '../app.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import * as moment from 'moment';

@Component({
  selector: 'app-auctions',
  templateUrl: './auctions.component.html',
  styleUrls: ['./auctions.component.scss']
})
export class AuctionsComponent implements OnInit {

  private changeView: IMessageStream | Promise<IMessageStream>;
  private changeAuctionView: IMessageStream | Promise<IMessageStream>;
  _MainViews = AdminMainViews;
  _SubViews = AdminSubViews;

  @ViewChild('agGrid', { static: true }) agGrid: AgGridAngular;

  // selectedIndex;
  rowSelection;
  gridFiletr = IGridFilter;
  filteredData;
  filteredDataCount = {
    all: 0,
    active: 0,
    unpublished: 0,
    draft: 0,
    trash: 0
  }

  filterTitle = "All";

  activeColumnDefs = [

    {  
      headerName: '',
      field: 'check',
      width: 140,
      resizable: true,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      
    },
    {  
      headerName: 'Name',
      field: 'title',
      width: 420,
      resizable: true,
     
    },
    {  
      headerName: 'Status',
      field: 'status',
      width: 346,
      resizable: true,
      cellRenderer: this.statusRenderer
      
    },
    {  
      headerName: 'Categories',
      field: 'category',
      width: 345,
      resizable: true,
      
    },
    {  
      headerName: 'Submitted on',
      field: 'date',
      width: 300,
      resizable: true,
      
    },
    {  
      headerName: '',
      field: 'action',
      width: 50,
      resizable: true,
      cellRendererFramework: RegularGridPopupComponent,
      cellRendererParams: {
        items:[
          {
            label: 'View Auction Details',
            id: 6,
            color:"rgba(74, 137, 152, 1)"
          },
          {
            label: 'Edit',
            id: 7
          },
          {
            label: 'Active',
            id: 8
          },
          {
            label: 'Unpublsih',
            id: 9
          },
          {
            label: 'Trash',
            id: 10
          }
        ],
        clickHandler: this.dropdownClickHander.bind(this)
          
      }
      
    } 
  
  ];
  
  activeRowData = [];

  bulkActions = [
    {
      label: 'Bulk Actions'
    },
    {
      label: 'Active',
      value: {
        id: 1,
        name: 'Active'
      }
    },
    {
      label: 'Unpublish',
      value: {
        id: 2,
        name: 'Unpublsih'
      }
    },
    {
      label: 'Delete',
      value: {
        id: 3,
        name: 'Delete'
      }
    }
  ]

  selectedRowData;
  option = dropdownItems;
  selectedBulkAction;

  constructor(
    private elem: ElementRef,
    public  extApi:  ExtapiService,
    private interconnect: Interconnect,
    public appService: AppService,
    
    ) { 

    this.rowSelection = 'multiple';

    this.changeView = interconnect.connectToListener('home/changeView', 'leftbar');
    if (this.changeView['then']) {
      this.changeView['then']((notifier) => this.changeView = notifier);
    }

  }

  async ngOnInit() {

    try {

      this.activeRowData = await this.extApi.viewAllAuctions({});

      this.activeRowData.forEach(aRow => {
        aRow.date = moment(aRow.date).format("MM-DD-YYYY HH:mm:ss")
      })

      this.refresh();
      
    } catch (error) {

      console.log(error);
      alert(error.statusText);
      
    }

   


  }

  
  // onChange($event) {

  //   this.selectedIndex = $event.index;

  // }

  // onAuctionSelect(params){
  //   alert("clicked")
  // }

  gridFilter(filetr: IGridFilter){

    let tabButtons = Array.from(this.elem.nativeElement.querySelectorAll('.filters'));

    tabButtons.forEach((tab: any) => {

      let btnId = tab.id;

      if(btnId === filetr)
        tab.classList.add('select');
      else
        tab.classList.remove('select');

    });

    switch(filetr){
      case this.gridFiletr.all: 
        this.filteredData = this.activeRowData; 
        this.filterTitle = "All"; 
      break;

      case this.gridFiletr.active: 
        this.filteredData = this.activeRowData.filter(aRow => aRow.status === 'Active');
        this.filterTitle = "Active";
        break;

      case this.gridFiletr.draft: 
        this.filteredData = this.activeRowData.filter(aRow => aRow.status === 'Draft');
        this.filterTitle = "Draft";
        break;

      case this.gridFiletr.unpublished: 
        this.filteredData = this.activeRowData.filter(aRow => aRow.status === 'Unpublish'); 
        this.filterTitle = "Unpublished";
        break;
      case this.gridFiletr.trash: 
        this.filteredData = this.activeRowData.filter(aRow => aRow.status === 'Trash'); 
        this.filterTitle = "Trash";
        break;
    }

  }

  statusRenderer(params:any){

    let status = params.data.status
 
    var ui = document.createElement('div');

    switch (status) {

      case "Unpublish":
        ui.style.color = '#EE212E';
      break;

      case "Active":
        ui.style.color = '#64C8BC';
      break;

      case "Trash":
        ui.style.color = '#616161';
      break;
        
      default:
        ui.style.color = '#6554C0';
      break;

    }

    ui.innerHTML =
      `
        <div style=" text-align: center;">${status}</div>
        
      `;

    return ui;

  }

  // onRowSelected(params) {

  //   var x = params.api.getSelectedRows();
  //   console.log(x)
  // }

  onCellClicked(params) {

    if (params.event.target.dataset.action == 'toggle' && params.column.getColId() == 'action') {

      const cellRendererInstances = params.api.getCellRendererInstances({
        rowNodes: [params.node],
        columns: [params.column],
      });

      if (cellRendererInstances.length > 0) {
        const instance = cellRendererInstances[0].getFrameworkComponentInstance();
        instance.togglePopup();
      }

    }

    this.selectedRowData = params.data;
    

  }

  async dropdownClickHander(params:dropdownItems) {

    switch (params) {

      case this.option.Edit:
        this.userChangeView(this._MainViews.auctions, this._SubViews.act_add_new);
        break;


      case this.option.Active:
        var data = this.selectedRowData;
        data.status = AUCStatus.Active;
        try {

          await this.extApi.updateAuctionStatus({data});
          
        } 

        catch (error) {

          console.log(error);
          alert(error.statusText);
          
        }
        break;


      case this.option.Unpublish:
        var data = this.selectedRowData;
        data.status = AUCStatus.Unpublished;
        try {

          await this.extApi.updateAuctionStatus({data});
          
        } 
        catch (error) {

          console.log(error);
          alert(error.statusText);
          
        }
        break;
    

      case this.option.Trash:
        var data = this.selectedRowData;
        data.status = AUCStatus.Trash;
        try {

          await this.extApi.updateAuctionStatus({data});
          
        } 
        catch (error) {

          console.log(error);
          alert(error.statusText); 
        }
        break;
    

      default:
        break;

    }

    this.refresh();
    this.agGrid.api.redrawRows();

    
  }

  async refresh(){

    this.activeRowData.forEach(aRow => {

      switch(aRow.status){
        case AUCStatus.Active: aRow.status = "Active"; break;
        case AUCStatus.Draft: aRow.status = "Draft"; break;
        case AUCStatus.Trash: aRow.status = "Trash"; break;
        case AUCStatus.Unpublished: aRow.status = "Unpublish"; break;
      }

      switch(aRow.category){
        case AUCCategories.Single: aRow.category = "Single"; break;
        case AUCCategories.Duplex: aRow.category = "Duplex"; break;
        case AUCCategories.Commercial: aRow.category = "Commercial"; break;
      }

    })

    this.filteredData = this.activeRowData;

    this.filteredDataCount = {
      all: this.activeRowData.length,
      active: this.activeRowData.filter(aRow => aRow.status === 'Active').length,
      unpublished: this.activeRowData.filter(aRow => aRow.status === 'Unpublish').length,
      draft: this.activeRowData.filter(aRow => aRow.status === 'Draft').length,
      trash: this.activeRowData.filter(aRow => aRow.status === 'Trash').length
    }

  }

  userChangeView(viewId, subViewId = undefined){

    (this.changeView as IMessageStream).emit({mainView: viewId, subView: subViewId, userAction:true, data: this.selectedRowData});

  }

  async applyBulkAction(){

    var selectedRows = this.agGrid.api.getSelectedRows();

    
    if(selectedRows.length){

      if(this.selectedBulkAction){

        var action = this.selectedBulkAction.id;

        var ids = [];
  
        switch (action) {
  
          case auctionBulkActions.Active:

            selectedRows.forEach(aRow => {
    
              ids.push(aRow.auction_id)
              
  
            });

            try {
    
              await this.extApi.updateAuctionStatus({data:{auction_id:ids,status: AUCStatus.Active}});
              this.activeRowData = await this.extApi.viewAllAuctions({});
              this.refresh();
              this.agGrid.api.deselectAll();
              this.agGrid.api.redrawRows();
              this.selectedBulkAction = this.bulkActions[0];
              
            } 

            catch (error) {

              console.log(error);
              alert(error.statusText);
              
            }

            break;


          case auctionBulkActions.Unpublished:

            selectedRows.forEach(aRow => {
    
              ids.push(aRow.auction_id)
              

            });

            try {
    
              await this.extApi.updateAuctionStatus({data:{auction_id:ids,status: AUCStatus.Unpublished}});
              this.activeRowData = await this.extApi.viewAllAuctions({});
              this.refresh();
              this.agGrid.api.deselectAll();
              this.agGrid.api.redrawRows();
              this.selectedBulkAction = this.bulkActions[0];
              
            } 

            catch (error) {

              console.log(error);
              alert(error.statusText);
              
            }
            break;

  
          case auctionBulkActions.Delete:
  
            selectedRows.forEach(aRow => {
  
              ids.push(aRow.auction_id)
              
   
            });
  
            try {
  
              await this.extApi.deleteAuctions(ids);
              this.agGrid.api.deselectAll();
              this.filteredData = this.filteredData.filter((x) =>{
              return selectedRows.indexOf(x) < 0;
              });
              this.activeRowData = await this.extApi.viewAllAuctions({});
              this.refresh();
              this.agGrid.api.redrawRows();
              this.selectedBulkAction = this.bulkActions[0];
              
            } 
  
            catch (error) {
  
              console.log(error);
              alert(error.statusText);
              
            }
            break;
    
        }

        ids = [];

      }

      else{

        alert("please select a action")

      } 
     
    }
    
    else{

      alert("please select the rows")
    }

  }

  downloadXl(){

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Users');

    worksheet.columns = [
      { header: 'Name', key: 'title', width: 30 },
      { header: 'Status', key: 'status', width: 16 },
      { header: 'Category', key: 'category', width: 16 },
      { header: 'Submitted on', key: 'date', width: 20 }
    ];

    worksheet.addRows(this.activeRowData, "n")

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, "all_auction.xlsx");
    })

  }


}
