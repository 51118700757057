import { Component, Input, OnInit } from '@angular/core';
import { Interconnect } from 'ng-interconnect';
import { ExtapiService } from '../extapi.service';

@Component({
  selector: 'app-choose-contract-popup',
  templateUrl: './choose-contract-popup.component.html',
  styleUrls: ['./choose-contract-popup.component.scss']
})
export class ChooseContractPopupComponent implements OnInit {
  
  @Input() display: boolean = false;
  broadcaster;
  selectedContract;
  selectedContractDetails;
  contracts = [];

  constructor(
    private interconnect: Interconnect,
    public  extApi:  ExtapiService
  ) { 

    this.broadcaster = this.interconnect.createBroadcaster('choose-contract-popup');
  }

  async ngOnInit(): Promise<void> {

    this.contracts = await this.extApi.viewAllContracts({});

    // Get only active contracts
    this.contracts = this.contracts.filter(con => con.status === 1);


  }

  close(){

    this.display = false;
    this.broadcaster.emit({
      display:false
    });

  }

  setcontractDetails(){

    this.selectedContractDetails = this.selectedContract;
    console.log(this.selectedContractDetails)
    

  }

  choose(){

    if(this.selectedContract !== undefined){

      this.broadcaster.emit({
        display:false,
        contract_id: this.selectedContractDetails.contract_id,
        date: this.selectedContractDetails.date,
        description: this.selectedContractDetails.description,
        name:this.selectedContractDetails.name,
        status: this.selectedContractDetails.status
      });

      this.display = false;

    }

    else{
      alert("Plese choose a contract")
    }

    

  }

}
