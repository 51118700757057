import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { IMessageStream, Interconnect } from 'ng-interconnect';
import { AdminMainViews, AdminSubViews, IGridFilterUsers, userBulkActions, userRole, userStatus } from 'src/app/app.types';
import { ExtapiService } from 'src/app/extapi.service';


@Component({
  selector: 'app-all-users',
  templateUrl: './all-users.component.html',
  styleUrls: ['./all-users.component.scss']
})
export class AllUsersComponent implements OnInit {
  
  @ViewChild('agGrid', { static: true }) agGrid: AgGridAngular;
  private changeView: IMessageStream | Promise<IMessageStream>;
  _MainViews = AdminMainViews;
  _SubViews = AdminSubViews;

  rowSelection;
  gridFiletr = IGridFilterUsers;
  filteredData;
  filteredDataCount = {
    all: 47,
    administrator:3,
    subscriber: 27,
    premium: 8,
    bulider: 9
    
  }

  filterTitle = "All";

  activeColumnDefs = [

    {  
      headerName: '',
      field: 'check',
      width: 200,
      resizable: true,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      
    },
    {  
      headerName: 'User Name',
      field: 'username',
      width: 350,
      resizable: true,
      cellRenderer: this.userRenderer
      
    },
    {  
      headerName: 'Name',
      field: 'full_name',
      width: 300,
      resizable: true,
     
    },
    {  
      headerName: 'Email',
      field: 'email',
      width: 400,
      resizable: true,
      
    },
    {  
      headerName: 'Role',
      field: 'role',
      width: 300,
      resizable: true,
      
    },
 
  
  ];
  
  activeRowData = [];

  bulkActions = [
    {
      label: 'Bulk Actions'
    },
    {
      label: 'Set Password Reset',
      value: {
        id: 1,
        name: 'Set Password Reset'
      }
    },
    {
      label: 'Delete',
      value: {
        id: 2,
        name: 'Delete'
      }
    }
    
  ];

  selectedRowData;
  selectedBulkAction;

  constructor(
    private elem: ElementRef,
    public  extApi:  ExtapiService,
    private interconnect: Interconnect) { 

    this.rowSelection = 'multiple';
    this.changeView = interconnect.connectToListener('home/changeView', 'leftbar');
    if (this.changeView['then']) {
      this.changeView['then']((notifier) => this.changeView = notifier);
    }

  }

  async ngOnInit(): Promise<void> {

    try {

      var allUsers = await this.extApi.viewAllUsers({});

      

      this.activeRowData = allUsers.filter(item => item.status === userStatus.Active);
     

      this.activeRowData.forEach(aRow => {

        switch(aRow.role){
          case userRole.administrator: aRow.role = "Administrator"; break;
          case userRole.bulider: aRow.role = "Bulider"; break;
          case userRole.premium: aRow.role = "Premium"; break;
          case userRole.subscriber: aRow.role = "Subscriber"; break;
        }

      })

      this.filteredData = this.activeRowData;

      this.filteredDataCount = {
        all: this.activeRowData.length,
        administrator: this.activeRowData.filter(aRow => aRow.role === 'Administrator').length,
        subscriber: this.activeRowData.filter(aRow => aRow.role === 'Subscriber').length,
        premium: this.activeRowData.filter(aRow => aRow.role === 'Premium').length,
        bulider: this.activeRowData.filter(aRow => aRow.role === 'Bulider').length
      }

      // console.log( this.activeRowData)
      
    }

    catch (error) {

      console.log(error);
      alert(error.statusText);
      
    }


  }


  gridFilter(filetr: IGridFilterUsers){

    let tabButtons = Array.from(this.elem.nativeElement.querySelectorAll('.filters'));

    tabButtons.forEach((tab: any) => {

      let btnId = tab.id;

      if(btnId === filetr)
        tab.classList.add('select');
      else
        tab.classList.remove('select');

    });

    switch(filetr){
      case this.gridFiletr.all: 
        this.filteredData = this.activeRowData; 
        this.filterTitle = "All"; 
      break;

      case this.gridFiletr.administrator: 
        this.filteredData = this.activeRowData.filter(aRow => aRow.role === 'Administrator');
        this.filterTitle = "Administrator";
        break;

      case this.gridFiletr.subscriber: 
        this.filteredData = this.activeRowData.filter(aRow => aRow.role === 'Subscriber');
        this.filterTitle = "Subscriber";
        break;

      case this.gridFiletr.premium: 
        this.filteredData = this.activeRowData.filter(aRow => aRow.role === 'Premium'); 
        this.filterTitle = "Premium";
        break;
      case this.gridFiletr.bulider: 
        this.filteredData = this.activeRowData.filter(aRow => aRow.role === 'Bulider'); 
        this.filterTitle = "Bulider";
        break;
    }

  }

  statusRenderer(params:any){

    let status = params.data.Status
 
    var ui = document.createElement('div');

    switch (status) {

      case "Unpublish":
        ui.style.color = '#EE212E';
      break;

      case "Active":
        ui.style.color = '#64C8BC';
      break;

      case "Trash":
        ui.style.color = '#616161';
      break;
        
      default:
        ui.style.color = '#6554C0';
      break;

    }

    ui.innerHTML =
      `
        <div style=" text-align: center;">${status}</div>
        
      `;

    return ui;

  }

  userRenderer(params:any){

    let user = params.data.username
    // let userpic = params.data.pic

    let userpic = params.data.profile_image || './assets/user.png'

    
 
    var ui = document.createElement('div');
    ui.style.display = "flex";
    ui.style.alignItems = "center";
    ui.style.justifyContent = "center";

    ui.innerHTML =
      `
        <img src = "${userpic}"/ style="width: 29px; height: 29px;">
        <div style = " margin-left: 14px;">${user} </div>
        
      `;

    return ui;

  }

  onRowClicked(params) {

    this.selectedRowData = params.data;

    (this.changeView as IMessageStream).emit({mainView: AdminMainViews.users, subView: AdminSubViews.user_profile, user:true, data: this.selectedRowData});
      

  }


  async applyBulkAction(){

    var selectedRows = this.agGrid.api.getSelectedRows();

    if(selectedRows.length){

      if(this.selectedBulkAction){

        var action = this.selectedBulkAction.id;

        var ids = [];
  
        switch (action) {
  
          case userBulkActions.SetPasswordReset:
            
            break;
  
          case userBulkActions.Delete:
  
            selectedRows.forEach(aRow => {
  
              ids.push(aRow.client_id)
              
   
            });
  
            try {
  
              await this.extApi.updateUsersStatus({id:ids,status:userStatus.Deleted});
              this.agGrid.api.deselectAll();
              this.filteredData = this.filteredData.filter((x) =>{
              return selectedRows.indexOf(x) < 0;
              });
              this.filteredDataCount = {
                all: this.filteredData.length,
                administrator: this.filteredData.filter(aRow => aRow.role === 'Administrator').length,
                subscriber: this.filteredData.filter(aRow => aRow.role === 'Subscriber').length,
                premium: this.filteredData.filter(aRow => aRow.role === 'Premium').length,
                bulider: this.filteredData.filter(aRow => aRow.role === 'Bulider').length
              }
              this.agGrid.api.redrawRows();
              this.selectedBulkAction = this.bulkActions[0];
             
              
            } 
  
            catch (error) {
  
              console.log(error);
              alert(error.statusText);
              
            }
            break;
    
        }


        

      }

      else{

        alert("please select a action")

      } 
     
    }
    
    else{

      alert("please select the rows")
    }

  }

  // onSelectionChanged(params){

  //   var x = params.api.getSelectedRows()
  //   console.log(x)

  // }


}
