<div class="container">

    <div class="section-1">
        <div class="ui-inputgroup">
            <span class="ui-inputgroup-addon"><i class="pi pi-search"></i></span>
            <input type="text" pInputText placeholder="Search for users">         
        </div>
    </div>

    <div class="section-2">
        <!-- <button *ngIf="showContractorsButton" (click)="showContractorsPopup()">
            <img src="./assets/profile/Add button Contract.png">
        </button> -->
    </div>

    <div class="section-3">

        <!-- <div class="profile" (click)="userChangeView()">
            <label>Angelia Jasmine</label>
            <img src="./assets/profile/icons/profile.png">
        </div> -->

        <div class="notifications">
            <button type="button">
                <img src="./assets/notify-icon.png">
            </button>
           
        </div>

        
    </div>

</div>

