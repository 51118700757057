import { Component, OnInit, ViewChild } from '@angular/core';
import { ExtapiService } from '../../extapi.service';
import { UIChart } from "primeng/chart/chart";

import * as moment from 'moment';
import { AppService } from '../../app.service';

const DATE_FORMAT = "YYYY-MM-DD";

@Component({
  selector: 'app-user-analytics',
  templateUrl: './user-analytics.component.html',
  styleUrls: ['./user-analytics.component.scss']
})
export class UserAnalyticsComponent implements OnInit {

  @ViewChild("chart") chart: UIChart;

  selectedChartData: any;
  chartData: any;
  
  chartOptions: any;
  chartHeight: number = 300;
  chartWidth: number = 1074;

  performances :  any;
  periods :  any;
  selectedPeriod: any;
  
  currentOne;
  selectedOne = {
    id: 1
  };
  id :number = 1;

  detailContent;
  allTimeUsage;
  detailContentPerformances;
  periodicallyUsage;
  softwareUsageRec;
  
  title = "Site Activity";
 
  selectedPerformancePeriod;
  totalEntranceRec;
  selectedperi;
  todayDate = moment().format(this.appService.DATE_FORMAT);
  filterMonth = moment().format("YYYY-MM");
  perfomanceChartData

  constructor(private extApi: ExtapiService, private appService: AppService) {

    this.chartData = {

      week:{

        labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
        datasets: [
          {
            data: [],
            backgroundColor: '#4A8998',
            borderColor: '#4A8998',
          }
        ]
  
      },

      month: {
        
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: '#4A8998',
          borderColor: '#4A8998',
        }
      ]

      }
    }

    this.chartOptions = {

      legend: {display: false},
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          maxBarThickness: 15,
          gridLines : {
            display : false
          },
          ticks: {
            fontFamily: 'Poppins', 
            fontSize: 11, 
            fontWeight: 400,
          }
        }],
        yAxes: [{
          ticks: {
            fontFamily: 'Poppins', 
            fontSize: 11, 
            fontWeight: 400,
            min: 0,
            beginAtZero: 0,
            precision: 0
            
          }
        }]
      }
    }

    this.performances = [
      {
        label: 'Weekly Performance',
        value: {
          id: 1,
          name: 'Weekly Performance'
        }
      },
      {
        label: 'Monthly Performance',
        value: {
          id: 2,
          name: 'Monthly Performance'
        }
      }
    ]

    this.periods = [

      // This will calculate
      [],
      [
        
        {
          label: 'January',
          value: {
            id: 1,
            name: 'January'
          }
        },

        {
          label: 'February',
          value: {
            id: 2,
            name: 'February'
          }
        },

        {
          label: 'March',
          value: {
            id: 3,
            name: 'March'
          }
        },

        {
          label: 'April',
          value: {
            id: 4,
            name: 'April'
          }
        },

        {
          label: 'May',
          value: {
            id: 5,
            name: 'May'
          }
        },

        {
          label: 'Jane',
          value: {
            id: 6,
            name: 'Jane'
          }
        },

        {
          label: 'July',
          value: {
            id: 7,
            name: 'July'
          }
        },

        {
          label: 'August',
          value: {
            id: 8,
            name: 'August'
          }
        },

        {
          label: 'September',
          value: {
            id: 9,
            name: 'September'
          }
        },

        {
          label: 'October',
          value: {
            id: 10,
            name: 'October'
          }
        },

        {
          label: 'November',
          value: {
            id: 11,
            name: 'November'
          }
        },

        {
          label: 'December',
          value: {
            id: 12,
            name: 'December'
          }
        }

      ]
      
    ]

    this.allTimeUsage = [
 
      {
        title: 'Total Entrants',
        count: '0'

      },

      {
        
        title: 'Total Visitors',
        count: '0'

      },

      {
        
        title: '60 Days Inactive Users',
        count: '0'

      },

      {

        title: 'Total Registed Users',
        count: '0'

      }
  
    ]

    this.periodicallyUsage = [
      
      {
        title: 'Total Registered',
        count: '0'
  
      },
  
      {
        
        title: 'Returning Users',
        count: '0'
  
      },
  
      {
        
        title: 'Total Visitors',
        count: '0'
  
      },
  
      {
  
        title: 'Total Entrants',
        count: '0'
  
      }
      
  
    ]
    
      
  }

  async ngOnInit() {

    try {

      this.softwareUsageRec = await this.extApi.getSoftwareUsage();

      // this.softwareUsageRec = {
      //   "allVisitors": [
      //       {
      //           "id": 1,
      //           "client_id": null,
      //           "date": "2024-06-25T00:00:00.000Z",
      //           "ip_address": "203.189.189.129"
      //       },
      //       {
      //           "id": 2,
      //           "client_id": 19,
      //           "date": "2024-06-25T00:00:00.000Z",
      //           "ip_address": "127.0.0.1"
      //       }
      //   ],
      //   "allEntered": [
      //       {
      //           "client_id": 19,
      //           "date": "2024-06-06T11:46:32.000Z"
      //       }
      //   ]
      // }

      // all time ------------------------------------
      // total visitors
      this.allTimeUsage[1].count = this.softwareUsageRec?.allVisitors?.length || 0;

      // total registered
      this.allTimeUsage[3].count = this.softwareUsageRec?.allRegistered?.length || 0;


      //total entrance
      this.allTimeUsage[0].count = this.softwareUsageRec?.allEntered?.length || 0;

    //   //60 days inactive users
    // let last60DaysInactiveUsers = 
      this.allTimeUsage[2].count = this.allTimeUsage[3].count -  this.softwareUsageRec?.totalPast60DaysEntrance?.length || 0;


      // last week ----------------------------------------------
      this.setLastWeekStats();


      // this.totalEntranceRec = await this.extApi.getMoneyUsage();


    }

    catch(err){
      console.log(err);
    }

    // set weeks labels for weekly performances
    var noOfWeeks = this.getDateRanges(moment().startOf('month').format(this.appService.DATE_FORMAT)).length;

    for(let no = 1; no <= noOfWeeks; no++ ){

      this.periods[0].push({

        label: 'Week '+ no ,
        value: {
          id: no,
          name: 'Week '+ no
        }
      })

    };

    this.selectedChartData = this.chartData.week;
    this.selectedPeriod = this.periods[0];
    this.selectedperi = this.selectedPeriod[0].value;
    this.selectedOne = this.performances[0].value;

    this.setChartPeriod();
    this.selectedPerformancePeriod = this.performances[0];
    
  }

  private async setPerformanceData(){

    let monthStartDate = moment(this.filterMonth).startOf('month').format(DATE_FORMAT);
    let monthEndDate = moment(this.filterMonth).endOf('month').format(DATE_FORMAT);

    this.perfomanceChartData = await this.extApi.getNumberOfUsers({startDate: monthStartDate, endDate: monthEndDate});

  }

  async setChartPeriod(){

    await this.setPerformanceData();

    let idx = this.performances.findIndex(el => el.value.id === this.selectedOne.id);

    // if(idx > -1){

      this.currentOne = this.performances[idx];
      this.id = this.currentOne.value.id ;
  
      // Config dropdowns
      switch (this.id) {
  
        // week
        case 1:
          this.selectedChartData = this.chartData.week;
          this.selectedPeriod = this.periods[0];
          break;
  
        // month
        case 2:
          this.selectedChartData = this.chartData.month;
          this.selectedPeriod = this.periods[1];
          break;
  
      }
  
      let _chartData = this.getChartdata(this.selectedOne.id === 1 ? chartDataFilterTypes.weekly : chartDataFilterTypes.monthly, this.selectedperi.id);
      this.selectedChartData.datasets[0].data = _chartData.chartData;
  
      this.selectedChartData.labels = _chartData.chartLabels
      // console.log(this.selectedChartData)
      setTimeout(() => this.chart.reinit())
       

    // }

  }

  setPerformancePeriod(period){

    switch (period.id) {

      // week
      case 1:this.setLastWeekStats(); break;

      // month
      case 2: this.setMonthlyStats(); break;

    }


  }

  private setLastWeekStats(){

    // registered users
    this.periodicallyUsage[0].count = this.softwareUsageRec?.totalLastWeekVisitors?.length || 0 ? ((this.softwareUsageRec?.totalLastWeekReg?.length || 0 / this.softwareUsageRec.totalLastWeekVisitors) * 100).toFixed(0) + "%" : "0%";

    // returning users
    this.periodicallyUsage[1].count =  this.softwareUsageRec?.totalLastWeekVisitors?.length || 0 ? (100 - (this.softwareUsageRec.totalLastWeekReg / this.softwareUsageRec.totalLastWeekVisitors) * 100).toFixed(0) + "%" : "0%";

    // total visitors
    this.periodicallyUsage[2].count =  this.softwareUsageRec?.totalLastWeekVisitors?.length || 0;

    // total entrance
    this.periodicallyUsage[3].count = this.softwareUsageRec?.totalLastWeekEntrance?.length || 0;

  }

  private setMonthlyStats(){


    // registered users
    this.periodicallyUsage[0].count = this.softwareUsageRec?.totalLastMonthVisitors ? ((this.softwareUsageRec.totalLastMonthReg / this.softwareUsageRec.totalLastMonthVisitors) * 100).toFixed(0) + "%" : "0%";

    // returning users
    this.periodicallyUsage[1].count = this.softwareUsageRec?.totalLastMonthVisitors ? (100 - (this.softwareUsageRec.totalLastMonthReg / this.softwareUsageRec.totalLastMonthVisitors) * 100).toFixed(0) + "%" : "0%";

    // total visitors
    this.periodicallyUsage[2].count = this.softwareUsageRec.totalLastMonthVisitors || 0;

    // total entrance
    this.periodicallyUsage[3].count = this.softwareUsageRec.totalLastMonthEntrance || 0;


  }

  getChartdata(type: number, subType: number){

    let chartData = [];
    let chartLabels = [];

    if(this.perfomanceChartData && Array.isArray(this.perfomanceChartData) && this.perfomanceChartData.length){

      this.perfomanceChartData.sort((a, c) => {

        let date1 = new Date(moment(a.date.split("T")[0]).format(this.appService.DATE_FORMAT)).getTime();
        let date2 = new Date(moment(c.date.split("T")[0]).format(this.appService.DATE_FORMAT)).getTime();
  
        return (date2 - date1);
  
      });
  
  
      if(type === chartDataFilterTypes.monthly){
  
        let currMonth = moment(this.filterMonth).month();
        let rowData = this.perfomanceChartData.filter(data => moment(data.date).month() === currMonth).sort((a, c) => {
  
          let date1 = new Date(moment(a.date.split("T")[0]).format(this.appService.DATE_FORMAT)).getTime();
          let date2 = new Date(moment(c.date.split("T")[0]).format(this.appService.DATE_FORMAT)).getTime();
    
          return (date1 - date2);
    
        });
  
        //Checking whether the selcted month has any recods.
        if(rowData.length !== 0){
  
          let monthlyChartData = this.getMonthlyData(rowData);
  
          chartData = monthlyChartData.map(obj => {
            return obj.amount;
          });
    
    
          chartLabels = chartData.map((el, idx) => {
    
            switch(idx + 1){
              case 1 :  
                return 'Week 1';
  
              case 2 : 
                return 'Week 2';
  
              case 3 : 
                return 'Week 3';
                
              default: 
                return  'Week ' + (idx + 1);
            }
    
          })
  
        }
  
      }
  
      if(type === chartDataFilterTypes.weekly){
  
        let rowData = this.perfomanceChartData.filter(data => moment(data.date).month() + 1 === moment().month() + 1).sort((a, c) => {
  
          let date1 = new Date(moment(a.date.split("T")[0]).format(this.appService.DATE_FORMAT)).getTime();
          let date2 = new Date(moment(c.date.split("T")[0]).format(this.appService.DATE_FORMAT)).getTime();
    
          return (date1 - date2);
    
        });
  
        let weeklyChartData = this.getWeeklyData(rowData, moment().format(this.appService.DATE_FORMAT), subType);
  
        chartData = Object.values(weeklyChartData).map(el => el);
  
        chartLabels = chartData.map((el, idx) => {
  
          switch(idx){
            case 0: return 'Sunday';
            case 1: return 'Monday';
            case 2: return 'Tuesday';
            case 3: return 'Wednesday';
            case 4: return 'Thrusday';
            case 5: return 'Friday';
            case 6: return 'Saturday';
          }
  
        })
  
      }
  
      // this.selectedperi = this.selectedPeriod[0].value;
      
  
      
    }
    else
      if(type === chartDataFilterTypes.monthly)
        chartLabels = ['Week 1', 'Week 2', 'Week 3', 'Week 4'];
      else
        chartLabels = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Trursday', 'Friday', 'Saturday'];

    return {chartData, chartLabels};

  }

  private getMonthlyData(rowData){

    let monthStartDate = moment(rowData[0].date).startOf('month').format(this.appService.DATE_FORMAT);
    let dateRangesArr = this.getDateRanges(monthStartDate);
    let amountsArr = [];

        
    rowData.forEach(dataPt => {

      dateRangesArr.forEach((dateObj, idx) => {

        let elidx = amountsArr.findIndex(el => el.weekNo === idx + 1);

        let dataPtDate = dataPt.date.split("T")[0];
				if(moment(dataPtDate).isSameOrAfter(dateObj.startDate) && moment(dataPtDate).isSameOrBefore(dateObj.endDate)){

					if(elidx !== -1)
						amountsArr[elidx].amount += 1;
					else
						amountsArr.push({weekNo: idx + 1, amount: 1})
				}
				else{

					if(!amountsArr[elidx]){

						amountsArr.push({weekNo: idx + 1, amount: 0});

					}

				}

      })

    })

    return amountsArr;


  }

  private getWeeklyData(rowData, currDate, weekNumber){

    let monthStartDate = moment(currDate).startOf('month').format(this.appService.DATE_FORMAT);
    let dateRangesArr = this.getDateRanges(monthStartDate);
    let currWeek = dateRangesArr[weekNumber - 1];
    let weeklyRowData = rowData.filter(dataPt => {

      let dataPtDate = dataPt.date.split("T")[0];
      if(moment(dataPtDate).isSameOrAfter(moment(currWeek.startDate)) && moment(dataPtDate).isSameOrBefore(moment(currWeek.endDate)))
        return dataPt;

    }) 

    let weeklyData = {0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0};

    for(let dataObj of weeklyRowData){

      let dayIdx = moment(dataObj.date).day();
      weeklyData[dayIdx] += 1;


    }

    return weeklyData;
  
  }

  private getDateRanges(date){

    let monthEndDay = moment(date).endOf('month').date();
    let monthEndDate = moment(date).endOf('month').format(this.appService.DATE_FORMAT);
    let datesArr = [];


    for(let currDay = 1; currDay <= monthEndDay; currDay++){

      let weekStartDate = moment(date).startOf('week').format(this.appService.DATE_FORMAT);
      let weekendDate = moment(date).endOf('week').format(this.appService.DATE_FORMAT);

      if(moment(weekStartDate).isSameOrBefore(monthEndDate)){

        if(currDay === 1)
          weekStartDate = date;

        if(!moment(weekendDate).isSameOrBefore(monthEndDate))
          weekendDate = monthEndDate;

        datesArr.push({startDate: weekStartDate, endDate: weekendDate});
        date = moment(weekendDate).add(1, 'days').format(this.appService.DATE_FORMAT);

        if(moment(weekendDate).isSameOrAfter(monthEndDate))
          break;

      }

    }

    return datesArr;

  }


}

enum chartDataFilterTypes {
  monthly = 1,
  weekly = 2
}


// const debugChartData = [
//   {
//     date: "01-01-2024",
//     amount: 2000
//   },
//   {
//     date: "01-08-2024",
//     amount: 4000
//   },
//   {
//     date: "01-15-2024",
//     amount: 4000
//   },
//   {
//     date: "01-22-2024",
//     amount: 4000
//   },
//   {
//     date: "01-29-2024",
//     amount: 4000
//   },

//   {
//     date: "02-01-2024",
//     amount: 2000
//   },
//   {
//     date: "02-08-2024",
//     amount: 4000
//   },
//   {
//     date: "02-15-2024",
//     amount: 4000
//   },
//   {
//     date: "02-22-2024",
//     amount: 4000
//   },
//   {
//     date: "02-29-2024",
//     amount: 4000
//   },




//   {
//     date: "03-01-2024",
//     amount: 2000
//   },
//   {
//     date: "03-02-2024",
//     amount: 500
//   },
//   {
//     date: "03-05-2024",
//     amount: 3000
//   },
//   {
//     date: "03-08-2024",
//     amount: 3000
//   },
//   {
//     date: "03-17-2024",
//     amount: 4000
//   },
//   {
//     date: "03-20-2024",
//     amount: 5000
//   },
//   {
//     date: "03-29-2024",
//     amount: 6000
//   },
//   {
//     date: "03-31-2024",
//     amount: 6000
//   },




//   {
//     date: "04-01-2024",
//     amount: 2000
//   },
//   {
//     date: "04-08-2024",
//     amount: 4000
//   },
//   {
//     date: "04-15-2024",
//     amount: 4000
//   },
//   {
//     date: "04-22-2024",
//     amount: 4000
//   },
//   {
//     date: "04-29-2024",
//     amount: 4000
//   },


//   {
//     date: "05-01-2024",
//     amount: 2000
//   },
//   {
//     date: "05-08-2024",
//     amount: 4000
//   },
//   {
//     date: "05-15-2024",
//     amount: 1000
//   },
//   {
//     date: "05-22-2024",
//     amount: 4000
//   },
//   {
//     date: "05-29-2024",
//     amount: 7000
//   }
// ]