export enum AdminMainViews {
    overview = 0,
    auctions = 1,
    users = 2,
    message = 3,
    ads = 4,
    archive = 5,
    settings = 6,
    profile = 7,
    contractors = 8
}

export enum AdminSubViews {
    act_all = 10,
    act_add_new = 11,
    act_contracts = 12,
    act_winners = 13,
    user_analytics = 14,
    all_users = 15,
    usr_add_new = 16,
    user_profile = 17,
    user_management = 18,
    msg_mass_msg = 19,
    msg_builder_side = 20,
    msg_client_side = 21,
    ads_new = 22,
    ads_all = 23,
    set_general = 24,
    set_permalink = 25,
    set_privacy = 26,
    con_create_scope = 27,
    con_save_scope = 28,
    con_project_board = 29,
    con_bidding_board = 30
}

export enum IGridFilter {
    all = 'all',
    active = 'active',
    unpublished = 'unpublished',
    draft = 'draft',
    trash = 'trash'
}

export enum IGridFilter2 {
    Deleted = 'Deleted',
    Banned = 'Banned',
}

export enum IGridFilterUsers {
    all = 'all',
    administrator = 'administrator',
    subscriber = 'subcribers',
    premium = 'premium',
    bulider = 'bulider'
    
}

export enum IGridFilterContractors {
    all = 'all',
    saved = 'saved',
    draft = 'draft',
    trash = 'trash',
    active = "active",
    closed = "closed"
}

export enum IGridFilterMsg {
    all = 'all',
    Message = 'Message',
    Email = 'Email',
    SMS = 'SMS',
    Trash = 'Trash'
}

export interface IUser {
    displayName: string,
    fullName: string,
    email: string,
    profileImage: string,
    
}

export enum AUCCategories {

    Single = 1,
    Duplex,
    Commercial
    
}

export enum AUCStatus{
    Active = 1,
    Unpublished,
    Draft,
    Trash

}

export enum ContractStatus{
    Active = 1,
    Unpublished,
    Draft,
    Trash

}

export enum dropdownItems{
    View = "View",
    Approve = "Approve",
    Decline  = "Decline",
    ScheduleMeet  = "ScheduleMeet",
    SendProjectBoard  = "SendProjectBoard",
    ViewAuction  = "ViewAuction",
    Edit  = "Edit",
    Active  = "Active",
    Unpublish  = "Unpublsih",
    Trash  = "Trash",
    ViewContract  = "ViewContract",
    SavedScopeOfWork = "SavedScopeOfWork"
}

export enum userStatus{
    Active = 1,
    Banned,
    Deleted

}

export enum userRole{
    administrator = 1,
	subscriber,
	premium ,
	bulider


}

export enum userBulkActions{

    SetPasswordReset =1,
    Delete,
    Recover

}

export enum auctionBulkActions{

    Active = 1,
    Unpublished,
    Delete

}

export enum contractBulkActions{

    Active = 1,
    Unpublished,
    Delete

}

export enum adsBulkActions{

    Live = 1,
    Unpublished,
    Delete

}

export enum adsStatus{
    Active = 1,
    Unpublished,
    Deleted

}

export enum winnerStatuses {
    Pending = 0,
    Approved = 1,
    Declined = 2,
    ScheduleMeet = 3,
    SendProjectBoard = 4
}