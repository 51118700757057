import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { RegularGridPopupComponent } from 'src/app/ds-components/ag-grid/regular-popup/regular-popup.component';
import { ExtapiService } from 'src/app/extapi.service';
import * as moment from 'moment';
import { AgGridAngular } from 'ag-grid-angular';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

import { dropdownItems, winnerStatuses } from '../../app.types';
import { OpenPortalComponent } from 'src/app/ds-components/ag-grid/open-portal/open-portal.component';

@Component({
  selector: 'app-winners',
  templateUrl: './winners.component.html',
  styleUrls: ['./winners.component.scss']
})
export class WinnersComponent implements OnInit, AfterViewInit {

  @ViewChild('agGrid', {static: true}) agGrid: AgGridAngular;

  activeColumnDefs = [

    {  
      headerName: 'Client ID',
      field: 'client_id',
      width: 150,
      resizable: true,
      
    },
    {  
      headerName: 'Date',
      field: 'winning_date',
      width: 250,
      resizable: true,
     
    },
    {  
      headerName: 'Auction Name',
      field: 'title',
      width: 300,
      resizable: true,
     
      
    },
    {  
      headerName: 'Auction ID',
      field: 'auction_id',
      width: 200,
      resizable: true,
      
    },
    {  
      headerName: 'Winner Name',
      field: 'username',
      width: 400,
      resizable: true,
      cellRenderer: this.nameRenderer
      
    },
    {  
      headerName: 'Open Portal',
      field: 'openPortal',
      width: 200,
      resizable: true,
      cellRendererFramework: OpenPortalComponent,
      cellRendererParams: {
        clickHandler: this.openPortal.bind(this) 
      }
      
    },
    {  
      headerName: '',
      field: 'action',
      width: 50,
      resizable: true,
      cellRendererFramework: RegularGridPopupComponent,
      cellRendererParams: {
        items:[
          {
            label: 'View',
            id: 1
          },
          {
            label: 'Approve',
            id: 2
          },
          {
            label: 'Decline',
            id: 3
          },
          {
            label: 'Schedule Meeting',
            id: 4
          },
          {
            label: 'Send Project Board',
            id: 5
          }
        ],
        clickHandler: this.dropdownClickHander.bind(this)
          
      }
      
    }
     
  ];
  
  activeRowData = [];
  selectedRowData;

  options = dropdownItems;

  showPortal: boolean = false;
  portalData;

  constructor(
    public  extApi:  ExtapiService
  ) { }

  ngOnInit(){


  }

  async ngAfterViewInit(){

    try {

      let winners = await this.extApi.viewAllWinners({});

      winners.forEach(aWinner => {

        this.activeRowData.push({
          client_id: aWinner.client_id,
          winning_date: moment(aWinner.winning_date.split("T")[0]).format("MM-DD-YYYY"),
          title: aWinner.title,
          auction_id: aWinner.auction_id,
          username: aWinner.username
        })
      })

      this.agGrid.api.setRowData(this.activeRowData);
      
      
    } 

    catch (error) {

      console.log(error);
      alert(error.statusText);
      
    } 

    

  }

  async dropdownClickHander(params: dropdownItems) {

    switch (params) {

      case this.options.View:
        alert("view")
      break;

      case this.options.Approve:

        await this.extApi.updateWinnersStatus({auction_id: this.selectedRowData.auction_id, client_id: this.selectedRowData.client_id, claime_status: winnerStatuses.Approved});

      break;

      case this.options.Decline:

        await this.extApi.updateWinnersStatus({auction_id: this.selectedRowData.auction_id, client_id: this.selectedRowData.client_id, claime_status: winnerStatuses.Declined});

      break;
    

      case this.options.ScheduleMeet:

        await this.extApi.updateWinnersStatus({auction_id: this.selectedRowData.auction_id, client_id: this.selectedRowData.client_id, claime_status: winnerStatuses.ScheduleMeet});

      break;

      case this.options.SendProjectBoard:

        await this.extApi.updateWinnersStatus({auction_id: this.selectedRowData.auction_id, client_id: this.selectedRowData.client_id, claime_status: winnerStatuses.SendProjectBoard});

      break;
    

    }

    
  }

  nameRenderer(params:any){

    let winnerName = params.data.username
 
    var ui = document.createElement('div');

    ui.style.color = 'rgba(74, 137, 152, 1)';

    ui.innerHTML =
      `
        <div style=" text-align: center;">${winnerName}</div>
        
      `;

    return ui;

  }

  openPortalRenderer(params:any){

    // let openPortal = params.data.openPortal
    let openPortal = "View"
 
    var ui = document.createElement('div');

    ui.style.color = '#F9A369';

    ui.innerHTML =
      `
        <div style="text-align: center;" (click)="openPortal()">${openPortal}</div>
        
      `;

    return ui;

  }

  onCellClicked(params) {

    if (params.event.target.dataset.action == 'toggle' && params.column.getColId() == 'action') {

      const cellRendererInstances = params.api.getCellRendererInstances({
        rowNodes: [params.node],
        columns: [params.column],
      });

      if (cellRendererInstances.length > 0) {
        const instance = cellRendererInstances[0].getFrameworkComponentInstance();
        instance.togglePopup();
      }

    }

    this.selectedRowData = params.data;

  }

  async openPortal(params){

    let winnerDetails = await this.extApi.getClientDetails({clientId: params.data.client_id});
    this.portalData = params.data;
    this.portalData.profile_image = winnerDetails[0].profile_image;

    this.showPortal = true;
  }

  closePopup(e){

    this.showPortal = false;

  }

  downloadXl(){

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Users');

    worksheet.columns = [
      { header: 'Client ID', key: 'client_id', width: 16 },
      { header: 'Auction ID', key: 'auction_id', width: 16 },
      { header: 'Winner Name', key: 'username', width: 30 },
      { header: 'Auction Name', key: 'title', width: 30 },
      { header: 'Date', key: 'winning_date', width: 20 }
    ];

    worksheet.addRows(this.activeRowData, "n")

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, "auction-winners.xlsx");
    })

  }

}
